import angular from 'angular';
import uiRouter from '@uirouter/angularjs';

import base from '../base/base';
import sectionsModule from '../base/sections-module';
import routerHelper from './controller/router-helper';
import resources from '../../components/api/resources/resources';
import optionsComponent from './directives/delete-component';

const MODULE_NAME = "standard-module";

angular.module(MODULE_NAME, [
  uiRouter,
  resources,
  routerHelper,
  sectionsModule,
  optionsComponent,
  base
]);

export default MODULE_NAME;