/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import {HttpHelper} from "../../../util/http-helper";

const MODULE_NAME = "preview";

angular.module(MODULE_NAME, [])

  .factory('Preview', function(adnResource, $http) {

    let transformResponse = function(data) {
        return _.isString(data) && data.indexOf("html") > -1 ? {document: data} : data;
      },
      actions = {
        get: {
          transformResponse: HttpHelper.obtain($http).appendResponseTransform(transformResponse)
        },
        save: {
          transformResponse: HttpHelper.obtain($http).appendResponseTransform(transformResponse)
        }
      };

    return adnResource({adServer: true, uri: 'preview'}, {}, actions);
  });

export default MODULE_NAME;