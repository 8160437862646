/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';

const MODULE_NAME = "ruleset";

angular.module(MODULE_NAME, [])

  .factory("Ruleset", function(adnResource) {
    return adnResource('rulesets');
  });

export default MODULE_NAME;