/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import translate from 'angular-translate';

const MODULE_NAME = "message-params-translation-filter";

angular.module(MODULE_NAME, [translate])

  .filter('adnMessageParams', function($translate, LocalNetworkProfile) {
    let paramTranslations = {
      objectiveDeliveryPrediction: function(params) {
        let key = 'lineItem.deliveryPrediction.' + params.objectiveDeliveryPrediction;
        return $translate.instant(key).toLowerCase();
      },
      objectiveType: function(params) {
        let key = 'lineItem.objective.' + params.objectiveType;
        if (parseInt(params.objectiveValue, 10) !== 1) {
          key += '.plural';
        }
        return LocalNetworkProfile.getNetworkTranslations(key) || $translate.instant(key);
      },
      status: function(params, config) {
        let keyBase = config && config.status ? config.status : 'status';
        return $translate.instant(keyBase + '.' + params.status).toLowerCase();
      },
      userState: function(params) {
        return $translate.instant('lineItem.userState.' + params.userState).toLowerCase();
      }
    };

    return function(message, config) {
      _.forEach(paramTranslations, function(tx, param) {
        if (_.has(message, ['params', param])) {
          message.params[param] = tx(message.params, config);
        }
        if (_.has(message, ['parameters', param])) {
          message.parameters[param] = tx(message.parameters, config);
        }
      });
      return message;
    };
  });

export default MODULE_NAME;