/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import {HttpHelper} from "../../../util/http-helper";
import {ResourceHelper} from "../../../util/resource-helper";

const MODULE_NAME = "order";

export function updateEndDateForManualInput(obj, param) {
  if (obj[param] && obj[param] instanceof Date && obj[param].getMinutes() === 59 && (obj[param].getSeconds() < 59 || obj[param].getMilliseconds() < 999)) {
    obj[param].setSeconds(59, 999);
  }
}

angular.module(MODULE_NAME, [])

  .factory('Order', function($http, adnResource, $q, dataViewResource) {
    let Order = adnResource('orders', {}, {
      copy: {
        params: {orderId: '@orderId', name: '@name', copyLineItems: '@copyLineItems', copyTargeting: '@copyTargeting', copyCreatives: '@copyCreatives', lineItemUserState: '@lineItemUserState'}
      },
      save: {
        transformRequest: HttpHelper.obtain($http).prependRequestTransform(function(order) {
          updateEndDateForManualInput(order, 'objectiveEndDate');

          ResourceHelper.checkObjectives(order);

          delete order.reportExecutions;
          return order;
        })
      }
    });

    Order.getListView = function(queryParams) {
      return dataViewResource.getView(queryParams, 'OrderListView').then(function(page) {
        _.forEach(page.results, function(datum) {
          _.set(datum, 'order', new Order(datum.order));
        });
        return page;
      });
    };

    Order.copyOrder = function(order, newId, lineItemUserState, copyLineItems, copyTargeting, copyCreatives) {
      if (!order || !order.id) {
        return $q.reject("Insufficient parameters for the order copy");
      }
      return Order.copy({
        id: order.id,
        orderId: newId || '',
        name: order.name,
        copyTargeting: _.isBoolean(copyTargeting) ? copyTargeting : true,
        copyCreatives: _.isBoolean(copyCreatives) ? copyCreatives : true,
        copyLineItems: _.isBoolean(copyLineItems) ? copyLineItems : true,
        lineItemUserState: lineItemUserState || ''
      });
    };

    Order.getDataView = function(params) {
      return dataViewResource.get('OrderView', params).then(function(dataView) {
        let order = new Order(dataView.order);
        order.advertiser = dataView.advertiser;
        return order;
      });
    };

    return Order;
  });

export default MODULE_NAME;