import { OBJECT_TYPE } from "../constants/object-type";
import * as _ from 'lodash';

export function calculateCrumbs(ctrl, currCrumb, data, newCrumbs) {
  if (!data) {
    return;
  }
  const crumbObjType = OBJECT_TYPE[currCrumb];
  newCrumbs.push(updateCrumb(ctrl, crumbObjType, data[crumbObjType.apiParam]));
}

export function updateCrumb(ctrl, crumbObjType, data) {
  const crumbObj = data || {};
  if (ctrl.perms[crumbObjType.hidePerm] === true) {
    return;
  }

  const permsFromObject = _.isArray(crumbObjType.perm) ? crumbObjType.perm : crumbObjType.perm ? [crumbObjType.perm] : null;
  const perm = !crumbObjType.sectionPerm && permsFromObject ? _.find(permsFromObject, function(pm) {
    return ctrl.perms[pm];
  })  : true;
  return {
    id: crumbObj.id,
    name: crumbObj.name || crumbObj.fileName || "undefined",
    obj: crumbObj,
    objectType: crumbObjType,
    perm: perm
  };
}