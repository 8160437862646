/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import { adsBlocked } from "../../../errors/ads-blocked";

import localNetworkProfile from '../../../../components/session/local-network-profile';

const MODULE_NAME = 'ghostery-banner-component';

angular.module(MODULE_NAME, [localNetworkProfile])

  .component('adnGhosteryBanner', {
    controllerAs: 'ctrl',
    controller: function($timeout, $window, $document) {
      const ctrl = this;

      ctrl.$onInit = function() {
        function checkVisibility() {
          $timeout(function() {
            ctrl.ghostery = !ctrl.forceOff && adsBlocked();
          }, 100);
        }

        if ($document[0].readyState === "complete") {
          checkVisibility();
        } else {
          $window.onload = function() {
            checkVisibility();
          };
        }
      };
    },
    template: `<div class="warningBanner blockedBanner" ng-if="!ctrl.forceOff && ctrl.ghostery">
                <p style="font-size: 25px">Ad Blocking Detected</p>
              <p>We've detected that your browser is blocking scripts that are necessary for this application.</p>
              <p>You may need to disable any Ad Blocking and/or Privacy browser extensions for this site and refresh the page to ensure the application works as expected.</p>
              <p><a ng-click="ctrl.forceOff=true"><span class="fa fa-times-circle"></span> Turn this warning off</a></p>
           </div>`
  });

export default MODULE_NAME;