/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';

import template from './masq-banner.html';

import localNetworkProfile from '../../../../components/session/local-network-profile';

const MODULE_NAME = 'masq-banner-component';

angular.module(MODULE_NAME, [localNetworkProfile])

  .component('adnMasqBanner', {
    bindings: {
      masq: '<'
    },
    controllerAs: 'ctrl',
    controller: function(LocalNetworkProfile) {
      const ctrl = this;
      ctrl.isSysNetwork = LocalNetworkProfile.getNetworkTag() === '2';
    },
    template: template
  });

export default MODULE_NAME;