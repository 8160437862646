/**
 * Copyright © 2024 Adnuntius AS.
 */
import * as _ from 'lodash';

export class HttpHelper {
  private static instance: HttpHelper;

  private constructor(private $http: any) {
  }

  private actionsGenerator(helper, requestTransformer, responseTransformer) {
    const responseTransformers = helper.appendResponseTransform(responseTransformer);

    return {
      query: {
        transformResponse: helper.appendResponseTransform(function (page, headers, status) {
          if (helper.isSuccess(status)) {
            page.results = _.map(page.results, responseTransformer);
          }
          return page;
        })
      },
      get: {
        transformResponse: responseTransformers
      },
      save: {
        transformRequest: helper.prependRequestTransform(requestTransformer),
        transformResponse: responseTransformers
      }
    };
  }

  static obtain($http: any): HttpHelper {
    if (!$http || !$http.defaults) {
      return null;
    }
    return this.instance || (this.instance = new HttpHelper($http));
  }

  appendResponseTransform(transform) {
    let defaults = this.$http.defaults.transformResponse;
    defaults = _.isArray(defaults) ? defaults : [defaults];
    return defaults.concat(transform);
  }

  prependRequestTransform(transform) {
    transform = _.isArray(transform) ? transform : [transform];
    return transform.concat(this.$http.defaults.transformRequest);
  }

  isSuccess(status) {
    return 200 <= status && status < 300;
  }

  jsonObjResponseTransformer(objName, wrap?) {
    const tx = function (data) {
      const fromJson = function (theObj, param) {
        const jsonData = {};
        _.set(jsonData, param, _.mapValues(theObj[param], function (val) {
          try {
            return JSON.parse(val);
          } catch (ex) {
            return val;
          }
        }));
        return jsonData[param];
      };

      const theSplit = objName.split('.');
      if (theSplit.length < 1 || !_.isArray(_.get(data, theSplit[0]))) {
        const obj = _.get(data, objName);
        if (_.isPlainObject(obj)) {
          data[objName] = fromJson(data, objName);
        }
      } else {
        _.forEach(data[theSplit[0]], function (d) {
          const obj = _.get(d, theSplit[1]);
          if (_.isPlainObject(obj)) {
            d[theSplit[1]] = fromJson(d, theSplit[1]);
          }
        });
      }
      return data;
    };
    return wrap ? this.appendResponseTransform(tx) : tx;
  }

  transformJsonObjActions(objName) {
    return this.actionsGenerator(this, function (pData) {
      const makeJson = function (jsonFodder, param) {
        const jsonData = {};
        _.set(jsonData, param, _.mapValues(_.get(jsonFodder, param), function (val) {
          return _.isObject(val) ? JSON.stringify(val) : val;
        }));
        return jsonData[param];
      };

      const data = _.cloneDeep(pData);
      const theSplit = objName.split('.');
      if (theSplit.length < 1 || !_.isArray(_.get(data, theSplit[0]))) {
        if (!_.isPlainObject(_.get(data, objName))) {
          return data;
        }
        data[objName] = makeJson(data, objName);
        return data;
      } else {
        const fodder = _.cloneDeep(_.get(data, theSplit[0]));
        _.forEach(fodder, function (d) {
          d[theSplit[1]] = makeJson(d, theSplit[1]);
        });
        data[theSplit[0]] = fodder;
        return data;
      }
    }, this.jsonObjResponseTransformer(objName));
  }

  transformJsonActions(prop) {
    return this.actionsGenerator(this, function (data) {
      if (!_.has(data, prop)) {
        return data;
      }
      const jsonFodder = _.cloneDeep(data);
      const obj = _.get(jsonFodder, prop);
      _.set(jsonFodder, prop, _.isString(obj) ? obj : JSON.stringify(obj));
      return jsonFodder;
    }, function (data) {
      if (_.has(data, prop)) {
        try {
          _.set(data, prop, JSON.parse(_.get(data, prop))); // need to parse JSON string
        } catch (ex) {
          // didn't get json in data[prop]
        }
      }
      return data;
    });
  }
}
