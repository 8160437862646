import * as _ from "lodash";
import {WARNINGS_TO_FIELD, WARNINGS_TO_HOOKS} from "./submit-warnings-to-fields";
import {apiInterpolate} from "./error/interpolate-warnings-filter";

export class SubmitCalls {
  static FORM_CONTROL_VALIDATIONS = [];

  static updateFormWarnings(form, model) {
    const doTheUpdate = () => {
      _.forEach(this.FORM_CONTROL_VALIDATIONS, function (val) {
        delete val.control.validation;
      });
      this.FORM_CONTROL_VALIDATIONS = [];
      _.forEach(_.filter(WARNINGS_TO_HOOKS, function (wf) {
        return wf.idHook;
      }), function (wf) {
        const el = document.getElementById(wf.idHook);
        if (el) {
          el.innerHTML = "";
        }
      });
      _.forEach(model.validationWarnings || [], (warning) => {
        const idHook = _.get(WARNINGS_TO_HOOKS, [warning.code, 'idHook']);
        if (idHook) {
          const el = document.getElementById(idHook);
          if (el) {
            el.innerHTML = warning.text;
          }
          return;
        }
        const fieldRefs = _.get(WARNINGS_TO_FIELD, [warning.code, 'field']);
        if (!fieldRefs) {
          return;
        }
        let control;
        _.find(fieldRefs, function (fieldRef) {
          const resourcePath = model.resourceName + "." + fieldRef;
          control = _.get(form, [fieldRef], "") || _.get(form, resourcePath, "") || _.get(form, ["resource.model." + fieldRef], "");
          return control;
        });
        if (control) {
          control.validation = apiInterpolate(warning);
          this.FORM_CONTROL_VALIDATIONS.push({
            control: control
          });
        }
      });
      form.hasValidationWarnings = !!_.find(model.validationWarnings, function (vw) {
        return vw.preventsAdServing;
      });
    };
    // eslint-disable-next-line angular/timeout-service
    window.setTimeout(doTheUpdate, 150);
  }
}