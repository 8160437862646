/**
 * Copyright © 2024 Adnuntius AS.
 */

import locationTemplate from './related-data-location.html';
import domainTemplate from './related-data-domain.html';
import trafficTemplate from './related-data-traffic.html';

type ChartType = {
  id: string;
  keyed?: string;
  eventHook: any;
};

type ChartInfo = {
  dataTypes: Record<string, ChartType>;
  statsMethod: string;
  csvTitle: string;
  template: string;
};

type RelatedChart = Record<string, ChartInfo>;

export const RELATED_CHART_DATA: RelatedChart = {
  TRAFFIC: {
    dataTypes: {
      deviceType: {
        id: 'deviceType',
        keyed: 'targeting.device',
        eventHook: {}
      },
      osType: {
        id: 'osType',
        keyed: 'targeting.device',
        eventHook: {}
      },
      mobileBrand: {
        id: 'mobileBrand',
        keyed: 'targeting.device',
        eventHook: {}
      }
    },
    statsMethod: 'getTraffic',
    csvTitle: 'Traffic',
    template: trafficTemplate
  },
  LOCATION: {
    dataTypes: {
      country: {
        id: 'country',
        eventHook: {}
      }
    },
    statsMethod: 'getLocation',
    csvTitle: 'Location',
    template: locationTemplate
  },
  DOMAIN: {
    dataTypes: {
      domain: {
        id: 'domain',
        eventHook: {}
      }
    },
    statsMethod: 'getDomain',
    csvTitle: 'Domain',
    template: domainTemplate
  }
};
