/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';

const MODULE_NAME = 'search-list-component';

angular.module(MODULE_NAME, [])

  .component('adnSearchList', {
    bindings: {
      list: '<'
    },
    controllerAs: 'ctrl',
    template: `
      <form class="searchListForm" ng-submit="ctrl.list.searchList()">
        <div class="row">
          <div class="col-md-3">
            <div class="input-group">
              <input type="text" ng-model="ctrl.list.searchTerm" class="form-control">
              <div class="input-group-btn">
                <button type="submit" class="btn btn-default">Search</button>
              </div>
            </div>
          </div>
          <div class="col-md-9" style="position: relative; left: -10px; top: -5px">
            <span ng-if="!ctrl.list.searchQuery.$resolved" style="display: inline-block; margin-left: 5px" class="fa fa-cog fa-spin"></span>
            <p ng-if="ctrl.list.searchTermCommitted && ctrl.list.searchQuery.$resolved" class="form-control-static"><a class="small" ng-click="ctrl.list.searchList(true)">clear search</a></p>
          </div>
        </div>
      </form>
    `
  });

export default MODULE_NAME;
