/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import {OBJECT_TYPE} from "../constants/object-type";

import template from './top-domain.html';
import moment from 'moment';

import resources from '../../../components/api/resources/resources';
import userPermissions from '../../../components/session/local-user-permissions';
import columnSelector from './column-selector-component';
import _ from "lodash";
import {Downloader} from "../../../components/util/downloader";

const MODULE_NAME = 'top-domain-table';

angular.module(MODULE_NAME, [resources, userPermissions, columnSelector])

  .component('adnTopDomainTable', {
    template: template,
    controllerAs: 'ctrl',
    bindings: {
      object: '<',
      parentType: '@',
      dateSelectorId: '@'
    },
    controller: function($scope, $rootScope, $log, $translate, statsResource, ChartOptionsUtil) {
      const ctrl = this;

      ctrl.sorting = {
        orderByParam: 'impressions',
        asc: true
      };

      ctrl.$onInit = function() {
        let chartOptionObject = OBJECT_TYPE[ctrl.parentType];
        let params = {
          id: ctrl.object.id,
          idKey: chartOptionObject.apiParam + 'Id',
        };

        ctrl.sortUpdate = function(field) {
          if (field === ctrl.sorting.orderByParam) {
            ctrl.sorting.asc = !ctrl.sorting.asc;
            return;
          }
          ctrl.sorting.orderByParam = field;
          ctrl.sorting.asc = true;
        };

        let chartOptions = ChartOptionsUtil.getOptions(chartOptionObject.caps);
        ctrl.parentParam = chartOptionObject.apiParam;
        const allFields = chartOptions.targetingFields;

        function updateValues() {
          ctrl.downloadFields = ctrl.fields.concat(ctrl.customFields);
          ctrl.downloadFields.unshift("Domain");
          ctrl.downloadColumnOrder = ctrl.fields.concat(ctrl.customFields);
          ctrl.downloadColumnOrder.unshift("domain");
          ctrl.csvTableTitle = "Domain Totals";
        }

        ctrl.getRows = function() {
          return ctrl.dataTotals;
        };

        ctrl.saveXls = function() {
          updateValues();
          new Downloader().saveXls2(ctrl.csvTableTitle, ctrl.downloadFields, ctrl.downloadColumnOrder, ctrl.dataTotals, allFields);
        };
        ctrl.saveDownloadFields = function() {
          updateValues();
        };

        ctrl.startDate = 'UNDEFINED';
        const domainCallDestroy = $rootScope.$on('chartDates' + chartOptions.idKey + (ctrl.dateSelectorId || ''), function(event, data) {
          if (ctrl.startDate !== 'UNDEFINED' && moment(ctrl.startDate).isSame(data.startDate) && moment(ctrl.endDate).isSame(data.endDate)) {
            return;
          }

          ctrl.startDate = data.startDate;
          ctrl.endDate = data.endDate;

          ctrl.loaded = false;
          ctrl.dataTotals = null;
          ctrl.error = false;
          ctrl.targetingExpression = false;

          if (ctrl.startDate && ctrl.endDate) {
            params.startDate = ctrl.startDate;
            params.endDate = ctrl.endDate;
          }

          statsResource.getDomain(params, function (data) {
            ctrl.dataTotals = data.chunks;
            ctrl.fields = ['impressions', 'rendered', 'viewables', 'clicks'];
            ctrl.customFields = _.filter(_.keys(data.totals), function(k) {
              return ctrl.fields.indexOf(k) < 0 && k !== 'visibles';
            });
            ctrl.loaded = true;
          }, function (err) {
            $log.warn(err);
            ctrl.error = true;
          }, $scope);
        });

        $scope.$on('$destroy', function() {
          domainCallDestroy();
        });
      };
    }
  });

export default MODULE_NAME;