/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

const MODULE_NAME = "segment";

angular.module(MODULE_NAME, [])

  .factory('Segment', function(adnResource) {
    const Segment = adnResource('segments', {}, {});

    Segment.ogQuery = angular.copy(Segment.query);
    Segment.query = function(a1, a2, a3) {
      return Segment.ogQuery(_.merge({}, a1, {dataSource: 'ADNUNTIUS'}), a2, a3);
    };

    return Segment;
  });

export default MODULE_NAME;