import angular from 'angular';

import template from './form-buttons.html';
import {formButtonVisibleCheck} from "./form-button-visible-check";

const MODULE_NAME = 'form-buttons';

angular.module(MODULE_NAME, [])
  .component('adnFormButtons', {
    bindings: {
      resource: '<',
      form: '<adnForm',
      avoidSticky: '@'
    },
    controller: function($window, $timeout, $scope) {
      const ctrl = this;
      ctrl.$onInit = function() {
        if (ctrl.avoidSticky) {
          return;
        }
        formButtonVisibleCheck($window, $timeout, $scope, ctrl.form);
      };
    },
    controllerAs: 'ctrl',
    template: template
  });

export default MODULE_NAME;