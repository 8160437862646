/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';

import {HttpHelper} from '../../../util/http-helper';

const MODULE_NAME = "user-profile";

angular.module(MODULE_NAME, [])

  .factory("UserProfile", function(adnResource, $http) {
    return adnResource('userprofiles', {
      context: null
    }, HttpHelper.obtain($http).transformJsonObjActions('profile'), "userProfile");
  });

export default MODULE_NAME;