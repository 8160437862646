/**
 * Copyright © 2024 Adnuntius AS.
 */

import angular from 'angular';
import localUserPermissions from '../../components/session/local-user-permissions';

const MODULE_NAME = "base-context-controller";

angular.module(MODULE_NAME, [localUserPermissions])

  .controller('BaseContextController', function(readOnly, sectionParam, LocalUserPermissions, LocalNetworkProfile) {
    const ctrl = this;
    const sectionPerms = LocalUserPermissions.getSectionPerms();
    const specificEditableParameter = sectionParam + 'Editable';
    if (sectionParam && Object.hasOwn(sectionPerms, specificEditableParameter)) {
      ctrl.readOnly = !sectionPerms[specificEditableParameter];
    } else {
      ctrl.readOnly = sectionParam && Object.hasOwn(sectionPerms, sectionParam) ? !sectionPerms[sectionParam] : readOnly;
    }
    ctrl.showVideoUpload = LocalNetworkProfile.showVideoUpload();
  });

export default MODULE_NAME;