/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import ngFileUpload from 'ng-file-upload';
import _ from 'lodash';
import {ApiConfig} from "../api/api";

const MODULE_NAME = "adn-shotter-helper";

angular.module(MODULE_NAME, [
  ngFileUpload
])

  .factory('adnShotter', function($http) {
    return {
      takeShot: function(id, url) {
        return $http.get(ApiConfig.obtain().getVersionedUri(['adnshotter', id]), {
          params: _.assign({}, {format: 'json', concise: true, url: url})
        }).then(_.iteratee('data'));
      }
    };
  });

export default MODULE_NAME;