/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';

const MODULE_NAME = "coupon";

angular.module(MODULE_NAME, [])

  .factory('Coupon', function(adnResource) {
    return adnResource('coupons');
  });

export default MODULE_NAME;