export const LOCALES = [
  'ar', 'ar_EG', 'ar_JO', 'my', 'da', 'en', 'en_US', 'en_GB', 'en_CA', 'en_AU', 'en_PH',
  'et', 'fr', 'fi', 'de', 'de_DE', 'de_AT', 'de_CH', 'el', 'in', 'is', 'lt', 'lb', 'ms', 'ms_BN', 'ms_MY', 'no',
  'pl', 'pt', 'pt_BR', 'pt_PT', 'ro', 'ru', 'es', 'es_ES', 'es_AR', 'sv', 'sv_SE', 'sv_FI', 'th', 'tl', 'tr', 'vi'
];

export const LOCALES_FIRST_DAY = {
  en: "SUNDAY",
  no: "SUNDAY",
  ar: "SUNDAY",
  my: "SUNDAY",
  da: "SUNDAY",
  et: "SUNDAY",
  fr: "SUNDAY",
  fi: "SUNDAY",
  de: "SUNDAY",
  el: "SUNDAY",
  in: "SUNDAY",
  is: "SUNDAY",
  lt: "SUNDAY",
  lb: "SUNDAY",
  ms: "SUNDAY",
  pl: "SUNDAY",
  pt: "SUNDAY",
  ro: "SUNDAY",
  ru: "SUNDAY",
  es: "SUNDAY",
  sv: "SUNDAY",
  th: "SUNDAY",
  tl: "SUNDAY",
  tr: "SUNDAY",
  vi: "SUNDAY",
  ar_EG: "SATURDAY",
  ar_JO: "SATURDAY",
  en_US: "SUNDAY",
  en_GB: "MONDAY",
  en_CA: "SUNDAY",
  en_AU: "SUNDAY",
  en_PH: "SUNDAY",
  de_DE: "MONDAY",
  de_AT: "MONDAY",
  de_CH: "MONDAY",
  ms_BN: "MONDAY",
  ms_MY: "MONDAY",
  pt_BR: "SUNDAY",
  pt_PT: "SUNDAY",
  es_ES: "MONDAY",
  es_AR: "MONDAY",
  sv_SE: "MONDAY",
  sv_FI: "MONDAY",
};

