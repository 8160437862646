/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import clipboard from 'ngclipboard';
import uiRouter from '@uirouter/angularjs';
import ngSanitize from 'angular-sanitize';
import { OBJECT_TYPE, ObjectTypeFinder as ObjectType } from "../../common/constants/object-type";

import resources from '../../../components/api/resources/resources';
import { Uuid } from "../../../components/util/uuid";

const MODULE_NAME = "version-copy-ctrl";

angular.module(MODULE_NAME, [
  uiRouter,
  resources,
  ngSanitize,
  clipboard
])
  .controller('VersionCopyCtrl', function($state, $injector, localUserProfile, LocalNetworkProfile) {
    const ctrl = this;

    const app = localUserProfile.getCurrentApp();
    const profileKey = 'adnLastViewed' + LocalNetworkProfile.getNetworkId() + (app === 'ADVERTISING' ? '' : app);
    ctrl.lastViewed = _.get(localUserProfile.get(), ['profile', profileKey]);
    ctrl.lastViewedObject = _.get(ctrl.lastViewed, [0]);
    ctrl.selectFrom = 'LAST_VIEWED';

    ctrl.loaded = true;
    ctrl.jsonGetTest = true;
    ctrl.objectId = $state.previousParams.id;
    ctrl.objectType = _.find(OBJECT_TYPE, function(ot) {
      return ot.stateName === $state.previousState.name;
    });
    ctrl.objectTypes = OBJECT_TYPE;

    let checkedJsonInput;
    ctrl.checkObject = function(isInternalCheck) {
      ctrl.jsonErr = "";
      if (!ctrl.jsonInput) {
        return;
      }

      try {
        checkedJsonInput = JSON.parse(ctrl.jsonInput);
      } catch(err) {
        ctrl.jsonErr = "Inputted JSON is not valid JSON!";
        return;
      }

      if (!isInternalCheck) {
        ctrl.jsonInferredObj = null;
        if (checkedJsonInput.url) {
          ctrl.jsonInferredObj = ObjectType.findObjectFromUrl(checkedJsonInput.url);
        }
        ctrl.jsonObjectId = checkedJsonInput.id || "";
        ctrl.jsonObjectNameNew = checkedJsonInput.name || "";
      }
    };

    let savedObjId = "";
    let savedObjUrlParam = "";
    ctrl.checkSave = function(andSave) {
      const objType = ctrl.jsonInferredObj || ctrl.setJsonObj;
      ctrl.checkObject(true);

      let Resource;
      try {
        Resource = $injector.get(objType.type);
      } catch(err) {
        ctrl.jsonErr = "Can't find the appropriate end point for a " + objType;
        return;
      }
      if (!_.isFunction(Resource.save)) {
        ctrl.jsonErr = "Can't find the end point for a " + objType;
        return;
      }

      ctrl.jsonGetTest = false;
      ctrl.jsonGetTestObjFound = false;
      const objId = ctrl.jsonObjectIdNew || Uuid.generate();

      const doSave = function() {
        checkedJsonInput.id = objId;
        checkedJsonInput.name = ctrl.jsonObjectNameNew || Uuid.generate();
        Resource.save(checkedJsonInput, function() {
          ctrl.jsonSuccess = "Save took place";
          savedObjId = objId;
          savedObjUrlParam = objType.stateName;
        }, function(err) {
          ctrl.jsonErr = err.data;
        });
      };

      if (andSave) {
        doSave();
        return;
      }

      Resource.get({id: objId}, function() {
        ctrl.jsonGetTest = true;
        ctrl.jsonGetTestObjFound = 'FOUND';
      }, function(err) {
        ctrl.jsonGetTest = true;
        ctrl.loaded = true;
        const status = _.get(err, ['status']);
        if (status === 404) {
          ctrl.jsonGetTestObjFound = 'UNFOUNDED';
          doSave();
        } else {
          ctrl.jsonErr = "An error happened trying to find a duplicate of your object";
        }
      });
    };

    ctrl.goToObject = function() {
      $state.go(savedObjUrlParam, {id: savedObjId});
    };

    ctrl.getJson = function() {
      ctrl.err = "";

      const objType = ctrl.selectFrom === 'LAST_VIEWED' ? _.get(ctrl, ['lastViewedObject', 'objectType']) : ctrl.objectType.type;
      const objId = ctrl.selectFrom === 'LAST_VIEWED' ? _.get(ctrl, ['lastViewedObject', 'id']) : ctrl.objectId;

      let Resource;
      try {
        Resource = $injector.get(objType);
      } catch(err) {
        ctrl.err = "Can't find the appropriate end point for a " + objType;
        return;
      }
      if (!_.isFunction(Resource.get)) {
        ctrl.err = "Can't find the end point for a " + objType;
        return;
      }
      ctrl.loaded = false;
      Resource.get({id: objId}, function(data) {
        ctrl.loaded = true;
        ctrl.objData = _.cloneDeep(data);

        _.forEach(['updateUser', 'createTime', 'createUser', 'updateUser', 'updateTime', 'network', 'velocity'], function(field) {
          delete ctrl.objData[field];
        });
      }, function(err) {
        ctrl.loaded = true;
        const status = _.get(err, ['status']);
        if (status === 404) {
          ctrl.err = "Couldn't find " + objType + " with an ID of " + objId;
        } else {
          ctrl.err = "An error has happened finding the JSON";
        }
      });
    };

  });

export default MODULE_NAME;