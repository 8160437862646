/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import localNetworkProfile from '../../components/session/local-network-profile';

import { OBJECT_TYPE } from "../../app/common/constants/object-type";

import resources from '../api/resources/resources';
import {LocalStorage} from "./local-storage";

const MODULE_NAME = "local-user-permissions";

const USER_PERMISSIONS_STORAGE_KEY = "user.permissions";

const userMenus = ['app.user', 'app.user-profile', 'app.team', 'app.role', 'app.notif', 'app.api-key'];
const designMenus = ['app.layout', 'app.layout-include', 'app.layout-example', 'app.marketplace-product', 'app.product', 'app.coupon'];
const queryMenus = ['app.quer'];
const dataExportAdminsMenus = ['app.data-exports'];

export const permissionsSetUp = function(LocalUserPermissions, LocalNetworkProfile, localUserProfile, $scope, $location, $state, ctrl) {
  ctrl.perms = LocalUserPermissions.getAllNetworkPermissions();
  ctrl.anyPerms = LocalUserPermissions.getAnyVisibility();
  ctrl.sectionPerms = LocalUserPermissions.getSectionPerms(localUserProfile.getCurrentApp());

  ctrl.app = localUserProfile.getCurrentApp();

  ctrl.experimental = LocalNetworkProfile.isExperimental() || localUserProfile.get('username') === 'office-mel@adnuntius.com' || $location.host() === 'localhost';
  ctrl.suiPlatform = LocalNetworkProfile.isSuiPlatform();
  ctrl.marketplacePlatform = LocalNetworkProfile.isMarketplacePlatform();
  ctrl.pureMarketplacePlatform = LocalNetworkProfile.isPureMarketplacePlatform();
  ctrl.showFirstParty = (LocalNetworkProfile.isPureMarketplacePlatform() || LocalNetworkProfile.getNetworkTag() === '1287') && ($location.host() === 'localhost' || localUserProfile.get("username") === "adnuntius@adnuntius.com");

  function menuSelector() {
    const defaultView = {
      dashboards: false,
      advertising: false,
      inventory: false,
      reports: false,
      queries: false,
      users: false,
      design: false,
      admin: false,
      segmentation: false,
      fields: false
    };

    ctrl.menuView = _.clone(defaultView);

    const urlState = _.get($state.current, 'name');
    if (ctrl.app === 'DATA') {
      if ($state.includes('app.dat-seg')) {
        ctrl.menuView.segmentation = true;
      } else if ($state.includes('app.fields') || $state.includes('app.mappings')) {
        ctrl.menuView.fields = true;
      } else if ($state.includes('app.data-queries')) {
        ctrl.menuView.queries = true;
      }
    }
    if (ctrl.app === 'ADVERTISING') {
      if ($state.includes('app.adv') || urlState.indexOf('app.reach-analysis') > -1 || urlState.indexOf('app.booking-calendar') > -1) {
        ctrl.menuView.advertising = true;
      } else if ($state.includes('app.inv') || urlState.indexOf('app.ad-tag') > -1) {
        ctrl.menuView.inventory = true;
      } else if ($state.includes('app.wor') || urlState.indexOf('app.wor') > -1) {
        ctrl.menuView.dashboards = true;
      }
    }
    if ($state.includes('app.rep')) {
      const queryMenu = _.find(queryMenus, function(u) {
        return urlState.indexOf(u) > -1;
      });
      if (queryMenu) {
        ctrl.menuView.queries = true;
      } else {
        ctrl.menuView.reports = true;
      }
    } else if ($state.includes('app.adm') || $state.includes('app.simple.release-notes') || $state.includes('app.simple.release-notes.release-note')) {
      const userMenu = _.find(userMenus, function(u) {
        return urlState.indexOf(u) > -1;
      });
      const designMenu = _.find(designMenus, function(urlPortion) {
        return urlState.indexOf(urlPortion) > -1;
      });
      const queryMenu = _.find(dataExportAdminsMenus, function(urlPortion) {
        return urlState.indexOf(urlPortion) > -1;
      });
      if (userMenu) {
        ctrl.menuView.users = true;
      } else if (designMenu) {
        ctrl.menuView.design = true;
      } else if (queryMenu && (ctrl.perms.AX_ADVERTISER_ADMIN || ctrl.perms.AX_PUBLISHER_ADMIN)) {
        ctrl.menuView.queries = true;
      } else {
        ctrl.menuView.admin = true;
      }
    }
    ctrl.menuViewFromPage = _.clone(ctrl.menuView);
  }

  $scope.$on('$stateChangeSuccess', menuSelector);
  menuSelector();

  ctrl.OBJECT_TYPE = OBJECT_TYPE;
};

angular.module(MODULE_NAME, [
  resources,
  localNetworkProfile
])

  .controller('PermissionsCtrl', function(LocalNetworkProfile, LocalUserPermissions, $scope, $location, $state, localUserProfile) {
    const ctrl = this;
    permissionsSetUp(LocalUserPermissions, LocalNetworkProfile, localUserProfile, $scope, $location, $state, ctrl);
  })

  .factory('LocalUserPermissions', function(LocalNetworkProfile) {
    function Profile(permissions, appsByNetwork, createTime, readOnlyTeams) {
      this.permissions = permissions;
      this.appsByNetwork = appsByNetwork;
      this.createTime = createTime;
      this.readOnlyTeams = readOnlyTeams;
    }

    Profile.prototype.isPartOf = function(networkId, groupId) {
      return !!_.find(this.permissions[networkId], function(s) {
        let permArray = _.get(s, groupId);
        return _.isArray(permArray) && permArray.length > 0;
      });
    };

    Profile.prototype.getPermissions = function(teamId) {
      let networkId = LocalNetworkProfile.getNetworkId();
      return _.flatten(_.map(_.get(this.permissions, [networkId, 'teamPermissions']), function(val, key) {
        if (teamId) {
          return teamId === key ? val : [];
        }
        return val;
      }));
    };

    Profile.prototype.isReadOnlyForTeamType = function(teamTypeToCheck) {
      let networkId = LocalNetworkProfile.getNetworkId();
      return _.reduce(_.flatten(_.map(_.get(this.readOnlyTeams, [networkId, 'teams']), function(teamType) {
        return teamType === teamTypeToCheck;
      })), function(sum, n) {
        return n || sum;
      }, false);
    };

    Profile.prototype.getNetworkPermissions = function(networkId, app) {
      const objPath = [networkId || LocalNetworkProfile.getNetworkId()];
      if (app) {
        objPath.push(app);
      }
      objPath.push('networkPermissions');
      return _.get(this.permissions, objPath) || [];
    };

    Profile.prototype.hasAnyPermission = function(permission) {
      return _.includes(this.getPermissions(), permission);
    };

    Profile.prototype.hasPermission = function(permission, groupId) {
      return _.includes(this.getPermissions(groupId), permission);
    };

    Profile.prototype.hasNetworkPermission = function(permission, networkId, app) {
      return _.includes(this.getNetworkPermissions(networkId || LocalNetworkProfile.getNetworkId(), app), permission);
    };

    Profile.prototype.save = function() {
      LocalStorage.obtain().setItem(USER_PERMISSIONS_STORAGE_KEY, this);
      return this;
    };

    Profile.fromObj = function(obj) {
      if (angular.isObject(obj)) {
        return _.create(Profile.prototype, obj);
      }
      return null;
    };

    let instance = Profile.fromObj(LocalStorage.obtain().getItem(USER_PERMISSIONS_STORAGE_KEY));

    return {
      create: function(authUser) {
        instance = new Profile(authUser.permissions, authUser.appsByNetwork, _.get(authUser, ['user', 'createTime']), authUser.readOnlyTeams);
        return instance.save();
      },
      destroy: function() {
        instance = null;
        return LocalStorage.obtain().remove(USER_PERMISSIONS_STORAGE_KEY);
      },
      get: function(prop) {
        return prop ? _.result(instance, prop) : instance;
      },
      isPartOf: function(networkId, teamId) {
        return instance ? instance.isPartOf(networkId, teamId) : false;
      },
      userPartOf: function(user, tagId) {
        return !!_.find(_.get(user, 'userRoles.rolesByNetwork') || [], function(role) {
          return _.get(role, "network.tagId") === tagId || _.get(role, "network.id") === tagId;
        });
      },
      hasAny: function(permission) {
        return instance ? instance.hasAnyPermission(permission) : false;
      },
      hasAnyReadOnly: function(teamType) {
        return instance ? instance.isReadOnlyForTeamType(teamType) : false;
      },
      has: function(permission, groupId) {
        return instance ? instance.hasPermission(permission, groupId) : false;
      },
      hasNetworkPermission: function(permission) {
        return instance.hasNetworkPermission(permission);
      },
      getApps: function(networkId) {
        const apps = _.cloneDeep(_.get(instance, ['appsByNetwork', networkId || LocalNetworkProfile.getNetworkId()])) || [];
        if (apps.length === 0 && apps.indexOf("ADVERTISING") < 0) {
          apps.push("ADVERTISING");
        }
        if (LocalNetworkProfile.isSuiPlatform()) {
          apps.push("SELF_SERVICE");
        }
        return apps;
      },
      getNetworkPermissionsObject: function(networkId) {
        if (!instance) {
          return {};
        }
        let permissionsObject = {};
        _.forEach(this.getApps(), function(app) {
          _.forEach(instance.getNetworkPermissions(networkId || LocalNetworkProfile.getNetworkId(), app === 'ADVERTISING' ? null : app), function(p) {
            permissionsObject[p] = true;
          });
        });
        return permissionsObject;
      },
      getAllVisPermissions: function(networkId) {
        return _.merge({}, this.getAllNetworkPermissions(networkId), this.getAnyVisibility());
      },
      getAllNetworkPermissions: function(networkId) {
        let showAllDefault = {
          MANAGE_SYSTEM: true,
          MANAGE_LAYOUTS: true,
          MANAGE_REPORT_TEMPLATES: true,
          MANAGE_BUSINESS: true,
          SELF_SERVICE_USER_ADMIN: true,
          DATA_ADMIN: false,
          DOWNLOAD_LOGS: false
        };
        const networkPermissionsObject = this.getNetworkPermissionsObject(networkId);
        if (LocalNetworkProfile.getDefaults().appSectionVisibility === 'SHOW_ALL') {
          if (networkPermissionsObject.DATA_ADMIN) {
            showAllDefault.DATA_ADMIN = true;
          }
          if (networkPermissionsObject.DOWNLOAD_LOGS) {
            showAllDefault.DOWNLOAD_LOGS = true;
          }
          return showAllDefault;
        }
        return networkPermissionsObject;
      },
      getAnyVisibility: function(getActuals) {
        let showAllDefaults = {
          MANAGE_ADVERTISING: true,
          MANAGE_PUBLISHING: true,
          AX_PUBLISHER: false,
          AX_ADVERTISER: false,
          RUN_REPORTS: true,
          SELF_SERVICE_OWN_ADVERTISING: false
        };
        if (!getActuals && LocalNetworkProfile.getDefaults().appSectionVisibility === 'SHOW_ALL') {
          return showAllDefaults;
        }
        return {
          SELF_SERVICE_OWN_ADVERTISING: this.hasAny('SELF_SERVICE_OWN_ADVERTISING'),
          MANAGE_ADVERTISING: this.hasAny('MANAGE_ADVERTISING'),
          MANAGE_PUBLISHING: this.hasAny('MANAGE_PUBLISHING'),
          AX_ADVERTISER: this.hasAny('AX_ADVERTISER'),
          CREATIVE_AGENCY: this.hasAny('CREATIVE_AGENCY'),
          AX_ADVERTISER_READ_ONLY: this.hasAnyReadOnly('AX_ADVERTISER'),
          AX_PUBLISHER: this.hasAny('AX_PUBLISHER'),
          AX_PUBLISHER_READ_ONLY: this.hasAnyReadOnly('AX_PUBLISHER'),
          RUN_REPORTS: this.hasAny('RUN_REPORTS'),
          DATA_ADMIN: this.hasAny('DATA_ADMIN')
        };
      },
      canBeShownAdvertiser: function() {
        return !this.isSelfService() && !this.isAxPublisherOnly();
      },
      isNotSelfService: function() {
        return !this.isSelfService() && !this.isAxPublisherAdminOnly() && !this.isAxPublisherOnly();
      },
      isSelfServiceOwn: function() {
        const anyPerms = this.getAnyVisibility(true);
        return anyPerms.MANAGE_ADVERTISING === false && anyPerms.MANAGE_PUBLISHING === false && anyPerms.SELF_SERVICE_OWN_ADVERTISING === true;
      },
      isSelfService: function() {
        const anyPerms = this.getAnyVisibility(true);
        return anyPerms.MANAGE_ADVERTISING === false &&
          anyPerms.MANAGE_PUBLISHING === false &&
          anyPerms.SELF_SERVICE_OWN_ADVERTISING === true;
      },
      isAdOps: function() {
        const anyPerms = this.getAnyVisibility(true);
        return anyPerms.AX_ADVERTISER !== true && anyPerms.MANAGE_ADVERTISING === true;
      },
      isAxAdvertiser: function() {
        const anyPerms = this.getAnyVisibility(true);
        return anyPerms.AX_ADVERTISER === true && anyPerms.MANAGE_ADVERTISING !== true;
      },
      isAnyAxPublisher: function() {
        const anyPerms = this.getAnyVisibility(true);
        return anyPerms.AX_PUBLISHER === true || anyPerms.AX_PUBLISHER_ADMIN === true;
      },
      isAxPublisherOnly: function() {
        const anyPerms = this.getAnyVisibility(true);
        return anyPerms.AX_PUBLISHER === true && anyPerms.MANAGE_PUBLISHING !== true;
      },
      isAxPublisherAdminOnly: function() {
        const anyPerms = this.getAnyVisibility(true);
        return anyPerms.AX_PUBLISHER_ADMIN === true && anyPerms.MANAGE_PUBLISHING !== true;
      },
      getSectionPerms: function(app) {
        const networkPerms = this.getAnyVisibility();
        const teamPerms = this.getAllNetworkPermissions();
        const allPerms = _.merge({}, networkPerms, teamPerms);
        const networkDefaults = LocalNetworkProfile.getDefaults();
        const canDoReachAnalysis = networkDefaults.runReportsOptions.indexOf("REACH_ANALYSIS") > -1;
        return {
          dashboards: networkPerms.MANAGE_ADVERTISING === true || networkPerms.AX_ADVERTISER === true,
          advertising: networkPerms.AX_ADVERTISER === true || networkPerms.MANAGE_ADVERTISING === true || networkPerms.MANAGE_BUSINESS || networkPerms.CREATIVE_AGENCY === true || networkPerms.RUN_REPORTS === true || networkPerms.AX_ADVERTISER_READ_ONLY === true,
          advertisingEditable: allPerms.AX_ADVERTISER_ADMIN === true || allPerms.MANAGE_ADVERTISING === true || allPerms.MANAGE_BUSINESS === true,
          orders: networkPerms.AX_ADVERTISER === true || networkPerms.MANAGE_ADVERTISING === true || networkPerms.AX_ADVERTISER_READ_ONLY === true,
          ordersEditable: allPerms.AX_ADVERTISER === true || allPerms.MANAGE_ADVERTISING === true,
          lineItems: networkPerms.AX_ADVERTISER === true || networkPerms.MANAGE_ADVERTISING === true || networkPerms.SELF_SERVICE_OWN_ADVERTISING === true || networkPerms.AX_ADVERTISER_READ_ONLY === true,
          lineItemsEditable: allPerms.AX_ADVERTISER === true || allPerms.MANAGE_ADVERTISING === true,
          creatives: networkPerms.AX_ADVERTISER === true || networkPerms.MANAGE_ADVERTISING === true || networkPerms.CREATIVE_AGENCY === true || networkPerms.AX_ADVERTISER_READ_ONLY === true,
          libraryCreatives: networkPerms.AX_ADVERTISER === true || networkPerms.MANAGE_ADVERTISING === true || networkPerms.AX_ADVERTISER_READ_ONLY === true,
          creativesEditable: allPerms.AX_ADVERTISER === true || allPerms.MANAGE_ADVERTISING === true || networkPerms.CREATIVE_AGENCY === true,
          campaigns: networkPerms.MANAGE_ADVERTISING === true,
          advertisers: teamPerms.MANAGE_BUSINESS === true || networkPerms.AX_ADVERTISER === true || networkPerms.AX_ADVERTISER_READ_ONLY === true,
          advertisersEditable: allPerms.MANAGE_BUSINESS === true || allPerms.AX_ADVERTISER === true || allPerms.AX_ADVERTISER_READ_ONLY === true,
          folios: allPerms.AX_ADVERTISER === true || networkPerms.MANAGE_ADVERTISING === true || networkPerms.CREATIVE_AGENCY === true,
          creativeSets: allPerms.AX_ADVERTISER === true || networkPerms.MANAGE_ADVERTISING === true || networkPerms.CREATIVE_AGENCY === true,
          reachAnalysis: !LocalNetworkProfile.isPureMarketplacePlatform() && (networkPerms.MANAGE_ADVERTISING === true || networkPerms.AX_ADVERTISER === true || (networkPerms.RUN_REPORTS === true && canDoReachAnalysis)),
          bookingCalendar: networkPerms.MANAGE_ADVERTISING === true || networkPerms.AX_ADVERTISER === true,
          inventory: networkPerms.AX_PUBLISHER === true || networkPerms.MANAGE_PUBLISHING === true || networkPerms.MANAGE_BUSINESS || networkPerms.AX_PUBLISHER_ADMIN || networkPerms.MANAGE_SYSTEM,
          menuInventory: allPerms.AX_PUBLISHER === true || allPerms.MANAGE_PUBLISHING === true || allPerms.MANAGE_BUSINESS || allPerms.AX_PUBLISHER_ADMIN || allPerms.MANAGE_SYSTEM,
          reports: networkPerms.RUN_REPORTS===true || networkPerms.AX_ADVERTISER===true || networkPerms.AX_PUBLISHER===true,
          menuReports: allPerms.RUN_REPORTS===true,
          menuQueries: allPerms.MANAGE_BUSINESS || allPerms.AX_ADVERTISER_ADMIN || allPerms.AX_ADVERTISER===true || allPerms.MANAGE_PUBLISHING===true || allPerms.AX_PUBLISHER===true,
          menuDesign: app === 'ADVERTISING' && (allPerms.MANAGE_BUSINESS || allPerms.MANAGE_LAYOUTS || allPerms.MANAGE_SYSTEM || allPerms.AX_PUBLISHER),
          menuAdmin: (app === 'ADVERTISING' && (allPerms.MANAGE_BUSINESS || allPerms.MANAGE_LAYOUTS)) || allPerms.MANAGE_SYSTEM || allPerms.DATA_ADMIN || allPerms.DOWNLOAD_LOGS || app === 'DATA',
          admin: teamPerms.MANAGE_SYSTEM === true || teamPerms.MANAGE_LAYOUTS === true || teamPerms.SELF_SERVICE_USER_ADMIN === true || teamPerms.AX_ADVERTISER_ADMIN === true || teamPerms.AX_PUBLISHER_ADMIN === true,
          teams: teamPerms.MANAGE_SYSTEM === true || teamPerms.AX_ADVERTISER_ADMIN === true,
          roles: teamPerms.MANAGE_SYSTEM === true,
          layouts: teamPerms.MANAGE_LAYOUTS === true,
          cdnUploads: teamPerms.MANAGE_SYSTEM === true,
          customEvents: teamPerms.MANAGE_SYSTEM === true,
          referenceData: teamPerms.MANAGE_SYSTEM === true,
          contextServiceConfiguration: teamPerms.MANAGE_SYSTEM === true,
          externalDemandSource: teamPerms.MANAGE_SYSTEM === true,
          tiers: teamPerms.MANAGE_SYSTEM === true,
          network: teamPerms.MANAGE_SYSTEM === true,
          newButton: networkPerms.MANAGE_ADVERTISING === true || networkPerms.AX_ADVERTISER === true || networkPerms.MANAGE_PUBLISHING === true || networkPerms.AX_PUBLISHER === true || networkPerms.SELF_SERVICE_OWN_ADVERTISING === true
        };
      },
      hasNetwork: function(permission, networkId) {
        return instance ? instance.hasNetworkPermission(permission, networkId) : false;
      },
      hasDataNetworkPermission: function(permission) {
        return instance ? instance.hasNetworkPermission(permission, null, "DATA") : false;
      }
    };
  });

export default MODULE_NAME;