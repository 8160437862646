/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import {HttpHelper} from "../../../util/http-helper";

const MODULE_NAME = "adunit";

angular.module(MODULE_NAME, [])

  .factory("AdUnit", function(adnResource, $http) {

    let responseTransformer = function(au) {
      au.floorPrice = au.floorPrice || {};
      return au;
    };

    let AdUnit = adnResource('adunits', {}, {
      get: {
        transformResponse: HttpHelper.obtain($http).appendResponseTransform(responseTransformer)
      },
      query: {
        transformResponse: HttpHelper.obtain($http).appendResponseTransform(function(page, headers, status) {
          if (HttpHelper.obtain($http).isSuccess(status)) {
            page.results = _.map(page.results, responseTransformer);
          }
          return page;
        })
      },
      save: {
        transformRequest: HttpHelper.obtain($http).prependRequestTransform(function(au) {
          let amount = _.get(au.floorPrice, 'amount');
          if (!amount && amount !== 0) {
            au.floorPrice = null;
          }
          return au;
        }),
        transformResponse: HttpHelper.obtain($http).appendResponseTransform(responseTransformer)
      }
    }, "adUnit");

    AdUnit.create = function(defaults) {
      return new AdUnit(_.merge({
        urlAsCategory: true,
        pageSize: 1,
        targetingControl: 'NONE'
      }, defaults));
    };

    return AdUnit;
  });

export default MODULE_NAME;