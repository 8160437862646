import angular from "angular";
import _ from "lodash";

const timeouts = [200, 500, 1000, 1000, 1000, 1000, 1000, 1000, 1000];

export function formButtonVisibleCheck($window, $timeout, scope, form) {
  let index = 0;

  function doButtonCheck() {
    const el = $window.document.getElementsByClassName("formButtons")[0];
    if (!el) {
      return;
    }

    const mcel = $window.document.getElementsByClassName("mainContentContainer")[0];
    if (!mcel) {
      return;
    }
    const angMcel = angular.element(mcel);
    if (!angMcel) {
      return;
    }

    angMcel.removeClass("fixedFormButtons");
    angMcel.removeClass("formButtonsExtra");

    const rect = el.getBoundingClientRect();
    const isInViewport = rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= ($window.innerHeight || $window.document.documentElement.clientHeight) &&
      rect.right <= ($window.innerWidth || $window.document.documentElement.clientWidth);

    if (!isInViewport) {
      angMcel.addClass("fixedFormButtons");
      $timeout(function() {
        if (_.get(form, 'hasValidationWarnings') || _.get(form, ['$error', 'validation'])) {
          angMcel.addClass("formButtonsExtra");
        }
      }, 300);
    } else {
      if (timeouts[index]) {
        $timeout(doButtonCheck, timeouts[index]);
      }
      index++;
    }
  }

  $timeout(doButtonCheck);
  scope.$on("SUBMIT_PROCESSED", doButtonCheck);
}