/**
 * Copyright © 2023 Adnuntius AS.
 */
import angular from 'angular';
import _ from "lodash";
import {ApiConfig} from "../../api";

const MODULE_NAME = "publisher-info";

const BASE_PATH = "publisherinfo";

angular.module(MODULE_NAME, [])

  .factory('PublisherInfo', function($http) {
    return {
      order: function(orderId) {
        const uri = ApiConfig.obtain().getVersionedUri([BASE_PATH, 'order']);
        return $http.get(uri, {params: {order: orderId}}).then(_.iteratee('data'));
      },
      lineItem: function(lineItemId) {
        const uri = ApiConfig.obtain().getVersionedUri([BASE_PATH, 'lineitem']);
        return $http.get(uri, {params: {lineItem: lineItemId}}).then(_.iteratee('data'));
      },
      advertiser: function(advertiserId) {
        const uri = ApiConfig.obtain().getVersionedUri([BASE_PATH, 'advertiser']);
        return $http.get(uri, {params: {advertiser: advertiserId}}).then(_.iteratee('data'));
      }
    };
  });

export default MODULE_NAME;
