/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';

import template from './delete.html';
import confirmTemplate from './confirm-delete.html';
import specifyIdTemplate from './specify-id.html';

import localUserPermissions from '../../../components/session/local-user-permissions';
import standardActionsModule from '../../common/standard-actions-module';

const MODULE_NAME = 'delete-component';

const data = {
  ACTIVE: {
    title: 'Activation'
  },
  INACTIVE: {
    title: 'Archiving'
  },
  HIDDEN: {
    title: 'Deletion'
  }
};

angular.module(MODULE_NAME, [standardActionsModule, localUserPermissions])

  .component('adnDelete', {
    bindings: {
      model: '<',
      modelComms: '<',
      blockDelete: '<',
      confirmation: '<',
      isNew: '<'
    },
    template: template,
    controllerAs: 'ctrl',
    controller: function($uibModal, $state, LocalUserPermissions) {
      const ctrl = this;
      ctrl.$onInit = function() {

        ctrl.hideHidden = LocalUserPermissions.isAxAdvertiser() || LocalUserPermissions.isAxPublisherOnly() || LocalUserPermissions.isAxPublisherAdminOnly();

        ctrl.reallySetObjectState = function(objectState) {
          ctrl["rotate" + objectState] = true;
          ctrl.modelComms.updateObjectState(ctrl.model, objectState).then(function() {
            ctrl["rotate" + objectState] = false;
          });
        };

        ctrl.setObjectState = function(objectState) {
          if (objectState !== 'ACTIVE' && ctrl.confirmation) {
            const modalInstance = $uibModal.open({
              windowClass: '',
              template: confirmTemplate,
              controllerAs: 'modalCtrl',
              controller: /*@ngInject*/ function() {
                const modalCtrl = this;

                modalCtrl.textData = data[objectState];
                if (ctrl.confirmation.warningMessage) {
                  ctrl.confirmation.warningMessage = ctrl.confirmation.warningMessage.replace("{{action}}", modalCtrl.textData.title);
                }
                modalCtrl.objectData = ctrl.confirmation;

                modalCtrl.confirm = function() {
                  modalInstance.close();
                };
              }
            });
            modalInstance.result.then(function() {
              ctrl.reallySetObjectState(objectState);
            });
            return;
          }
          ctrl.reallySetObjectState(objectState);
        };

        ctrl.specifyId = function() {
          const modalInstance = $uibModal.open({
            windowClass: '',
            template: specifyIdTemplate,
            controllerAs: 'modalCtrl',
            controller: /*@ngInject*/ function() {
              const modalCtrl = this;

              modalCtrl.newId = ctrl.model.id;

              modalCtrl.error = false;
              modalCtrl.checkId = function() {
                ctrl.modelComms.updateObjectId(ctrl.model, modalCtrl.newId, function() {
                  modalCtrl.error = true;
                }, function() {
                  modalInstance.close();
                  $state.go($state.current.name, {id: modalCtrl.newId, isNew: true}, {notify: false, reload: false, location: 'replace'});
                });
              };
            }
          });
        };

        if (ctrl.isNew) {
          ctrl.modelComms.addSubscriber(function(data, type) {
            if (!type && (!!data.name || !!data.fileName)) {
              ctrl.isNew = false;
              ctrl.model.objectState = data.objectState || 'ACTIVE';
            }
          });
        }
      };
    }
  });

export default MODULE_NAME;