import angular from 'angular';
import translate from 'angular-translate';
import uiBootstrap from 'angular-ui-bootstrap';

import resources from '../../components/api/resources/resources';
import localUserPermissions from '../../components/session/local-user-permissions';
import listHelper from './controller/list-helper';
import sortingHeader from './directives/html/sorting-header-directive';
import actionsObjectStates from './directives/actions-object-states';
import objectStatesComponent from './directives/object-states-component';
import paginationComp from './directives/pagination/pagination-component';

const MODULE_NAME = "standard-list-module";

angular.module(MODULE_NAME, [
  uiBootstrap,
  translate,
  sortingHeader,
  actionsObjectStates,
  objectStatesComponent,
  listHelper,
  resources,
  localUserPermissions,
  paginationComp
]);

export default MODULE_NAME;
