/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from "lodash";
import {ApiConfig} from "../../api";

const MODULE_NAME = "invoice-translation";

angular.module(MODULE_NAME, [])

  .factory('InvoiceTranslationPreview', function($http) {
    return {
      preview: function(id, type) {
        const payProvider = type === 'PAYPAL' || type === 'PIN_PAYMENTS' ? type : 'PAYPAL';
        let uri = ApiConfig.obtain().getVersionedUri(['invoicetranslations', id, 'preview', payProvider]);
        return $http.get(uri).then(_.iteratee('data'));
      }
    };
  })

  .factory('InvoiceTranslation', function(adnResource) {
    return adnResource('invoicetranslations', {}, {}, 'invoiceTranslation');
  });

export default MODULE_NAME;