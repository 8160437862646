/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

const MODULE_NAME = "external-ad-unit";

angular.module(MODULE_NAME, [])

  .factory("ExternalAdUnit", function(adnResource) {

    let ExternalAdUnit = adnResource('externaladunits', {}, {}, "externalAdUnit");

    ExternalAdUnit.create = function(defaults) {
      return new ExternalAdUnit(_.merge({}, defaults));
    };

    return ExternalAdUnit;
  });

export default MODULE_NAME;