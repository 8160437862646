/**
 * Copyright © 2024 Adnuntius AS.
 */
import * as _ from 'lodash';
import {ALL_END_POINTS} from "../../app/config/all-end-points";

const buildUri = function (isRelative: boolean, scheme: string, host: string, port: number, basePath: string, path: string | Array<string>, isInternal?: boolean) {
  let uri = '';
  if (!isRelative) {
    uri = scheme + '://' + host;
    if (!!port && ((scheme === 'http' && port !== 80) || (scheme === 'https' && port !== 443))) {
      uri += ':' + port;
    }
  }

  path = _.map((_.isArray(path) ? path : path.split('/')), encodeURIComponent).join('/').replace(/%3A/gi, ':');

  if (isInternal) {
    basePath += "/internal";
  }

  return uri + '/' + _.compact([_.trim(basePath, '/'), _.trim(path, '/')]).join('/');
};

class RemoteConfig {
  config: any = _.assign({}, {isRelative: false}, ALL_END_POINTS.localhost.ADN_API);

  setConfig(config) {
    this.config = config;
    return this;
  }

  getUri(path: string) {
    return buildUri(this.config.isRelative, this.config.scheme, this.config.host, this.config.port, this.config.basePath, path);
  }

  getId() {
    return this.config.id;
  }

  getEnv() {
    return this.config.env;
  }

  getType() {
    return this.config.type;
  }

  updateConfig(newConfig) {
    _.assign(this.config, newConfig);
  }
}

class VersionedRemoteConfig extends RemoteConfig {
  constructor() {
    super();
    this.updateConfig({version: 'v1'});
  }

  getVersionedUri(path: string | Array<string>) {
    return buildUri(this.config.isRelative, this.config.scheme, this.config.host, this.config.port, _.compact([this.config.basePath, this.config.version]).join('/'), path);
  }

  getVersionedInternalUri(path: string) {
    return buildUri(this.config.isRelative, this.config.scheme, this.config.host, this.config.port, _.compact([this.config.basePath, this.config.version]).join('/'), path, true);
  }
}

export class ApiConfig extends VersionedRemoteConfig {
  private static instance: ApiConfig;

  private constructor() {
    super();
  }

  public static obtain(): ApiConfig {
    return this.instance || (this.instance = new ApiConfig());
  }
}

export class AdServerConfig extends RemoteConfig {
  private static instance: AdServerConfig;

  private constructor() {
    super();
  }

  public static obtain(): AdServerConfig {
    return this.instance || (this.instance = new AdServerConfig());
  }
}

export class DataServerConfig extends RemoteConfig {
  private static instance: DataServerConfig;

  private constructor() {
    super();
  }

  public static obtain(): DataServerConfig {
    return this.instance || (this.instance = new DataServerConfig());
  }
}

export class MauiConfig extends RemoteConfig {
  private static instance: MauiConfig;

  private constructor() {
    super();
  }

  public static obtain(): MauiConfig {
    return this.instance || (this.instance = new MauiConfig());
  }
}

(function(win: any) {
  const windowEnvKey = win.ADN_ENV_KEY;
  if (windowEnvKey) {
    const newEndPoints = (_.find(ALL_END_POINTS, function(val, key) {
      return key.indexOf(windowEnvKey) === 0;
    }) || ALL_END_POINTS.localhost);

    ApiConfig.obtain().setConfig(newEndPoints.ADN_API);
    AdServerConfig.obtain().setConfig(newEndPoints.ADN_AD_SERVER);
    DataServerConfig.obtain().setConfig(newEndPoints.ADN_DATA_SERVER);
    MauiConfig.obtain().setConfig(newEndPoints.MAUI);
  }
})(window);
