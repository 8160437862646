/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';

import asset from './asset/asset';
import {creativeFunctions} from "./creative";

const MODULE_NAME = "library-creative";

angular.module(MODULE_NAME, [asset])

  .factory('LibraryCreative', function($q, adnResource, Asset) {
    const LibCreative = adnResource('librarycreatives', {}, {
      copy: {
        params: {lineItemId: '@lineItemId', creativeId: '@creativeId', name: '@name', userState: '@userState'}
      }
    });

    creativeFunctions($q, LibCreative, Asset);

    return LibCreative;
  }, 'LibraryCreative');

export default MODULE_NAME;