/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import {ObjectTypeFinder} from "../../app/common/constants/object-type";

import localNetworkProfile from '../../components/session/local-network-profile';

const MODULE_NAME = "user-profile-saver";

angular.module(MODULE_NAME, [
  localNetworkProfile
])

  .factory('UserProfileSaver', function($injector, localUserProfile, LocalNetworkProfile) {

    let UserProfile;

    let saveObj = function(obj, profileKeyPrefix, objType) {
      if (obj.url) {
        let oType = objType || ObjectTypeFinder.findObjectFromUrl(obj.url);
        if (obj.id && obj.name && oType) {
          let networkId = LocalNetworkProfile.getNetworkId(),
            app = localUserProfile.getCurrentApp(),
            profileKey = profileKeyPrefix + networkId + (app === 'ADVERTISING' ? '' : app),
            localUser = localUserProfile.get(),
            profile = localUser.profile;

          profile[profileKey] = _.isArray(profile[profileKey]) ? profile[profileKey] : [];
          profile[profileKey].unshift({
            id: obj.id,
            objectType: oType.type,
            name: obj.name
          });
          profile[profileKey] = _.uniqBy(profile[profileKey], 'id');
          profile[profileKey] = _.slice(profile[profileKey], 0, 10);

          localUserProfile.publish(profileKey, profile[profileKey]);

          UserProfile = UserProfile || $injector.get('UserProfile');

          UserProfile.save({
            id: localUser.userId,
            profile: _.pick(profile, [profileKey])
          }, function(apiUser) {
            localUser = localUserProfile.safeSaveProfile(profileKey, apiUser.profile[profileKey]);
            profile = localUser.profile;
          });
        }
      }
    };

    return {
      saveObj: function(obj, profileKeyPrefix, objType) {
        saveObj(obj, profileKeyPrefix, objType);
      }
    };
  });

export default MODULE_NAME;