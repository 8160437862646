/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import translate from 'angular-translate';
import uiBootstrap from 'angular-ui-bootstrap';
import releaseNotesHelper from "../../../components/util/release-notes-helper";

import localNetworkProfile from '../../../components/session/local-network-profile';

import template from './release-note.html';

const MODULE_NAME = "release-note-display-comp";

angular.module(MODULE_NAME, [translate, uiBootstrap, releaseNotesHelper, localNetworkProfile])

  .component('adnReleaseNote', {
    template: template,
    controllerAs: 'ctrl',
    bindings: {
      note: "<",
      singular: "<",
      target: "<"
    },
    controller: function() {
      const ctrl = this;

      ctrl.$onInit = function() {
        ctrl.target = ctrl.target || "";
      };
    }
  });

export default MODULE_NAME;