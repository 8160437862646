import * as moment from "moment";

export const CHUNK_SIZE = {
  HALF_HOURLY: {
    key: 'half_hourly',
    duration: moment.duration(30, 'm'),
    closestMinutes: 10,
    name: 'Half Hourly',
    unit: 'Half Hour'
  },
  HOURLY: {
    key: 'hourly',
    duration: moment.duration(1, 'h'),
    closestMinutes: 20,
    name: 'Hourly',
    unit: 'Hour'
  },
  DAILY: {
    key: 'daily',
    duration: moment.duration(1, 'd'),
    closestMinutes: 660,
    name: 'Daily',
    unit: 'Day'
  },
  WEEKLY: {
    key: 'weekly',
    duration: moment.duration(1, 'w'),
    closestMinutes: 4500,
    name: 'Weekly',
    unit: 'Week'
  },
  MONTHLY: {
    key: 'monthly',
    duration: moment.duration(1, 'M'),
    closestMinutes: 7000,
    name: 'Monthly',
    unit: 'Month'
  }
};

export const OBJECT_STATE = {
  active: 'ACTIVE',
  hidden: 'HIDDEN',
  inactive: 'INACTIVE'
};

export const EXECUTION_STATUS = {
  pending: 'PENDING',
  processing: 'PROCESSING',
  completed: 'COMPLETED',
  rejected: 'REJECTED',
  failed: 'FAILED',
  cancelled: 'CANCELLED',
  queued: 'QUEUED',
  submitted: 'SUBMITTED',
  timedOut: 'TIMED_OUT'
};

export const ASSET_ERROR = {
  NO_FILE: 'no file',
  EMPTY_RESPONSE: 'empty response'
};

export const LINE_ITEM_EXECUTION_STATE = {
  notReady: 'NOT_READY',
  ready: 'READY',
  running: 'RUNNING',
  ended: 'ENDED',
  pausedOrReserved: 'PAUSED_OR_RESERVED'
};

export const LINE_ITEM_EXECUTION_STATE_ENDED_REASON = {
  objectiveReached: 'OBJECTIVE_REACHED',
  endDatePassed: 'END_DATE_PASSED',
  orderObjectiveReached: 'ORDER_OBJECTIVE_REACHED',
  orderEndDatePassed: 'ORDER_END_DATE_PASSED'
};

export const LINE_ITEM_OBJECTIVE_TYPE = {
  IMPRESSION: {
    id: 'IMPRESSION',
    order: 1
  },
  CLICK: {
    id: 'CLICK',
    order: 2
  },
  VISIBLE_IMPRESSION: {
    id: 'VISIBLE_IMPRESSION',
    order: 3
  },
  VIEWABLE_IMPRESSION: {
    id: 'VIEWABLE_IMPRESSION',
    order: 4
  },
  RENDERED_IMPRESSION: {
    id: 'RENDERED_IMPRESSION',
    order: 5
  },
  CONVERSION: {
    id: 'CONVERSION',
    order: 6
  },
  BUDGET: {
    id: 'BUDGET',
    order: 7
  }
};