import _ from "lodash";

function check(og, num) {
  if (num > -1) {
    return num;
  }
  if (_.get(og.value, 'currency')) {
    return og.value.amount || -1;
  }
  if (_.get(og.value, 'count')) {
    return og.value.count || -1;
  }
  return -1;
}

export function statComparator(v1, v2) {
  let v1Value = check(v1, v1.value || -1);
  let v2Value = check(v2, v2.value || -1);
  return (v1Value > v2Value) ? -1 : 1;
}