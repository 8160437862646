/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

const MODULE_NAME = "note";

angular.module(MODULE_NAME, [])

  .factory('Note', function(adnResource) {
    let NoteResource = adnResource('notes'),
      parentQuery = NoteResource.query;

    NoteResource.create = function(defaults) {
      return new NoteResource(_.assign({
        dataType: 'HTML'
      }, defaults));
    };

    NoteResource.query = function(ids, a1, a2) {
      parentQuery({ids: ids, orderBy: 'createTime'}, a1, a2);
    };

    return NoteResource;
  });

export default MODULE_NAME;