export const WARNINGS_TO_HOOKS: any = {
  "validation.warning.lineItem.no.creatives": {idHook: 'creativeWarningHook'},
  "validation.warning.creative_set.no.creatives": {idHook: 'creativeWarningHook'},
  "validation.warning.lineItem.no.valid.creatives": {idHook: 'creativeWarningHook'},
  "validation.warning.creative_set.no.valid.creatives": {idHook: 'creativeWarningHook'},
  "validation.warning.lineItem.invalidProduct": {idHook: 'productWarningHook'},
  "validation.warning.lineItem.missingProduct": {idHook: 'productWarningHook'},
  "validation.warning.lineItem.invalid.ax.product": {idHook: 'productWarningHook'},
  "validation.warning.lineItem.missing.ax.product": {idHook: 'productWarningHook'},
  "validation.warning.sponsorship.targeting.required": {idHook: 'targetingWarningHook'},
  "validation.warning.lineItem.invalid.site.targeting": {idHook: 'targetingWarningHook'},
};

export const WARNINGS_TO_FIELD: any = {
  "validation.warning.order.no.team": {field: ['team']},
  "validation.warning.order.no.active.team": {field: ['team']},
  "validation.warning.site.no.team": {field: ['teams']},
  "validation.warning.site.no.active.team": {field: ['teams']},
  "validation.warning.adUnit.no.site": {field: ['site']},
  "validation.warning.adUnit.much.have.targeting.type": {field: ['targetingOptions']},
  "validation.warning.adUnit.no.team": {field: ['site']},
  "validation.warning.lineItem.start.date.before.today": {field: ['startDate']},
  "validation.warning.lineItem.start.date.before.end.date": {field: ['startDate']},
  "validation.warning.lineItem.end.date.before.today": {field: ['endDate']},
  "validation.warning.lineItem.no.order": {field: ['order']},
  "validation.warning.lineItem.order.not.active": {field: ['order']},
  "validation.warning.lineItem.order.no.team": {field: ['order']},
  "validation.warning.lineItem.order.team.no.sites": {field: ['order']},
  "validation.warning.lineItem.unapproved": {field: ['userState']},
  "validation.warning.invalid.sui.lineitem.type": {field: ['userState']},
  "validation.warning.invalid.ax.lineitem.type": {field: ['userState']},
  "validation.warning.lineItem.tier.disabled": {field: ['tier']},
  "validation.warning.lineItem.no.tier": {field: ['tier']},
  "validation.warning.lineItem.tier.inactive": {field: ['tier']},
  "validation.warning.lineItem.objective.bid": {field: ['bidSpecification']},
  "validation.warning.lineItem.forbidden.objective": {field: ['objective']},
  "validation.warning.lineItem.minimum.budget.objective": {field: ['budget']},
  "validation.warning.lineItem.maximum.budget.objective": {field: ['budget']},
  "validation.warning.lineItem.requires.budget": {field: ['budget']},
  "validation.warning.lineItem.maximum.budget": {field: ['budget']},
  "validation.warning.lineItem.minimum.budget": {field: ['budget']},
  "validation.warning.lineItem.minimum.cpm": {field: ['bidSpecification.cpm.amount']},
  "validation.warning.lineItem.minimum.cpc": {field: ['bidSpecification.cpc.amount']},
  "validation.warning.lineItem.missing.impression.from.product": {field: ['objectives.RENDERED_IMPRESSION', 'objectives.IMPRESSION']},
  "validation.warning.lineItem.missing.objective.from.product": {field: ['objectives.RENDERED_IMPRESSION', 'objectives.IMPRESSION', 'objectives.CLICK']},
  "validation.warning.lineItem.invalid.bid.strategy.from.product": {field: ['bidStrategy']},
  "validation.warning.lineItem.missing.renderedImpressionObject": {field: ['bidSpecification.cpm.amount']},
  "validation.warning.invalid.cpm.fees.currency": {field: ['bidSpecification.cpm.amount']},
  "validation.warning.lineItem.net.cpm.bid.non.negative": {field: ['bidSpecification.cpm.amount']},
  "validation.warning.lineItem.invalid.budget.enddate": {field: ['endDate']},
  "validation.warning.lineItem.no.bid.spec": {field: ['bidSpecification.cpm.amount', 'bidSpecification.cpc.amount']},
  "validation.warning.lineItem.at.least.one.bid.higher.than.mins": {field: ['bidSpecification.cpm.amount', 'bidSpecification.cpc.amount']},
  "validation.warning.lineItem.no.objectives": {field: ['objective']},
  "validation.warning.lineItem.wrong.delivery.type.for.companions": {field: ['deduplicationLevel']},
  "validation.warning.lineItem.companions.with.external": {field: ['deduplicationLevel']},
  "validation.warning.sponsorship.percentage.required": {field: ['sponsorshipPercentage']},
  "validation.warning.invalid.ax.lineitem.companion_ad_type": {field: ['companionAds']},
  "validation.warning.invalid.sui.lineitem.companion_ad_type": {field: ['companionAds']},
};
