import angular from 'angular';
import translate from 'angular-translate';
import uiBootstrap from 'angular-ui-bootstrap';
import uiRouter from '@uirouter/angularjs';

import resources from '../../components/api/resources/resources';
import localUserPermissions from '../../components/session/local-user-permissions';
import localNetworkProfile from '../../components/session/local-network-profile';
import trafficComponent from './directives/related-data-chart-component';
import paginationComponent from '../common/directives/pagination/pagination-component';
import listHelper from '../common/controller/list-helper';
import performanceDirective from '../common/directives/performance-directive';
import speedoDirective from '../common/directives/speedo-directive';
import notesComp from './directives/notes/notes-component';
import topStatsTable from '../common/controller/top-stats-table-component';
import topTargetingTable from '../common/controller/top-targeting-table-component';
import topArticleTable from '../common/controller/top-articles-table-component';
import reportRequester from '../line-item/widget/report-requester-widget-directive';
import reportScheduler from '../line-item/widget/report-schedules-directive';

const MODULE_NAME = "standard-analysis-module";

angular.module(MODULE_NAME, [
  translate,
  uiBootstrap,
  notesComp,
  uiRouter,
  resources,
  localUserPermissions,
  localNetworkProfile,
  trafficComponent,
  paginationComponent,
  listHelper,
  topStatsTable,
  topTargetingTable,
  topArticleTable,
  performanceDirective,
  speedoDirective,
  reportRequester,
  reportScheduler
]);

export default MODULE_NAME;