/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from "lodash";
import {Uuid} from "../../../util/uuid";

const MODULE_NAME = "advertising-access-group";

angular.module(MODULE_NAME, [])

  .factory("AdvertisingAccessGroup", function(adnResource) {
    const AdvertisingAccessGroup = adnResource('advertisingaccessgroups', {}, {}, 'AdvertisingAccessGroup');
    AdvertisingAccessGroup.create = function(defaults) {
      return new AdvertisingAccessGroup(_.merge({
        id: Uuid.generate(),
        teams: [],
        sites: []
      }, defaults));
    };
    return AdvertisingAccessGroup;
  });

export default MODULE_NAME;