/**
 * Copyright © 2024 Adnuntius AS.
 */
import * as _ from 'lodash';
import {LINE_ITEM_OBJECTIVE_TYPE} from "../api/resources/resources-constants";

export class ResourceHelper {
  static checkMoney(object: Record<string, unknown>, field: Array<string>, moneyField?: string) {
    const rawMonetaryValue = _.get(object, field);
    const value = _.get(rawMonetaryValue, moneyField ? [moneyField, 'amount'] : 'amount');
    const managedValue = _.isString(value) ? parseFloat(value) : value;
    if (rawMonetaryValue && !_.isFinite(managedValue)) {
      _.set(object, field, null);
    } else if (rawMonetaryValue && _.isFinite(managedValue) && _.isString(value)) {
      _.set(object, moneyField ? field.concat(moneyField).concat('amount') : field.concat('amount'), managedValue);
    }
    return object;
  }

  static uiSrefUpdater(param: string) {
    // required because of the way ui-sref handles forward slashes
    return (param || '').replace(/~2F/g, "/");
  }

  static checkObjectives(obj) {
    if (_.isPlainObject(obj.objectives)) {
      _.forEach(LINE_ITEM_OBJECTIVE_TYPE, function(lObjType) {
        const objectiveValue = obj.objectives[lObjType.id];
        if (!objectiveValue) {
          delete obj.objectives[lObjType.id];
        } else if (_.isString(objectiveValue)) {
          obj.objectives[lObjType.id] = parseInt(objectiveValue, 10);
        } else if (_.get(objectiveValue, 'amount')) {
          obj.objectives[lObjType.id].amount = parseFloat(objectiveValue.amount);
        }
      });
    }
  }
}