/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from "angular";
import localUserPermissions from '../../../components/session/local-user-permissions';

const MODULE_NAME = 'actions-object-component';

angular.module(MODULE_NAME, [localUserPermissions])

  .component('adnObjectStates', {
    bindings: {
      list: '<',
      hideActivate: '@'
    },
    controllerAs: 'ctrl',
    controller: function(LocalUserPermissions) {
      const ctrl = this;
      ctrl.$onInit = function() {
        ctrl.hideActivate = ctrl.hideActivate || ctrl.hideActivate === "true";
        ctrl.hideHidden = LocalUserPermissions.isAxAdvertiser() || LocalUserPermissions.isAxPublisherOnly() || LocalUserPermissions.isAxPublisherAdminOnly();
      };
    },
    template: `
    <div class="btn-group">
      <button ng-if="!ctrl.hideActivate" type="button" ng-disabled="ctrl.list.showingInclude === 'ACTIVE'" class="btn btn-default btn-xs" ng-click="ctrl.list.doBulkAction('ACTIVE')">
        <span class="fa fa-power-off"></span> Activate
      </button>
      <button type="button" ng-disabled="ctrl.list.showingInclude === 'INACTIVE'" class="btn btn-default btn-xs" ng-click="ctrl.list.doBulkAction('INACTIVE')">
        <span class="fa fa-briefcase"></span> Archive
      </button>
      <button ng-if="!ctrl.hideHidden" type="button" ng-disabled="ctrl.list.showingInclude === 'HIDDEN'" class="btn btn-default btn-xs" ng-click="ctrl.list.doBulkAction('HIDDEN')">
        <span class="fa fa-trash"></span> Delete
      </button>
    </div>
    `
  });

export default MODULE_NAME;
