/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';

const MODULE_NAME = "site";

angular.module(MODULE_NAME, [])

  .factory("ConsentStatistic", function(adnResource) {
    return adnResource('consentstatistic', {}, {}, 'ConsentStatistic');
  })

  .factory("SiteCountry", function(adnResource) {
    return adnResource('sites/availablecountries', {}, {}, 'SiteCountry');
  })

  .factory("Site", function(adnResource) {
    return adnResource('sites', {summaryType: '@summaryType'});
  });

export default MODULE_NAME;