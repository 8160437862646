/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import textAngular from 'textangularjs';
import 'textangularjs/dist/textAngular-sanitize';

import template from './notes.html';

import resources from '../../../../components/api/resources/resources';
import {TOOLBAR_BUTTONS} from "./notes-constants.ts";
import {Uuid} from "../../../../components/util/uuid";

const MODULE_NAME = "notes-component";

angular.module(MODULE_NAME, [
  textAngular,
  resources
])

  .component('adnNotes', {
    bindings: {
      object: '<',
      param: '@',
      hideHeading: '@'
    },
    controllerAs: 'ctrl',
    template: template,
    controller: function($q, Note, localUserProfile, User) {
      const ctrl = this,
        localUser = localUserProfile.get();

      ctrl.toolbarButtons = TOOLBAR_BUTTONS;
      ctrl.notes = [];

      ctrl.$onInit = function() {
        ctrl.param = ctrl.param || 'notes';

        Note.query(_.map(ctrl.object[ctrl.param], "id"), function(page) {
          ctrl.notes = page.results;

          // might be able to avoid a call if the only person writing the notes is the current user. w00t!
          let userIds = _.map(ctrl.notes, function(n) {
            // doing this because of an updateUser bug in API
            return n.updateUser ? n.updateUser.id : n.createUser.id;
          });
          let searchUserIds = _.without(userIds, localUser.userId);
          let userPromise = User.query(searchUserIds).$promise;

          $q.when(userPromise).then(function(page) {
            const users = _.keyBy(page.results, "id");
            _.forEach(ctrl.notes, function(note) {
              // doing this because of an updateUser bug in API
              const safeUser = note.updateUser || note.createUser;
              if (safeUser.id === localUser.userId) {
                _.set(note, ['updateUser', 'displayName'], localUser.displayName);
                _.set(note, ['updateUser', 'name'], localUser.name);
              } else {
                const foundUser = users[safeUser.id];
                if (foundUser) {
                  _.set(note, ['updateUser', 'displayName'], foundUser.displayName);
                  _.set(note, ['updateUser', 'name'], foundUser.name);
                }
              }
            });
          });
        });

        ctrl.addNote = function() {
          let note = Note.create({
            id: Uuid.generate(),
            createUser: {
              id: localUser.userId,
              displayName: localUser.displayName,
              name: localUser.name
            },
            editThisNote: true
          });
          ctrl.notes.push(note);
        };

        ctrl.cancelEditing = function(note) {
          // checks if cancelling the editing of a note that hasn't already been saved.
          if (!note.updateTime) {
            ctrl.notes = _.reject(ctrl.notes, {id: note.id});
          }
        };

        ctrl.editingTheNote = function(note) {
          note.workingData = angular.copy(note.data);
        };

        ctrl.deleteNote = function(note) {
          ctrl.object[ctrl.param] = _.reject(ctrl.object[ctrl.param], {id: note.id});
          ctrl.object.savePartial(ctrl.param).then(function() {
            ctrl.notes = _.reject(ctrl.notes, {id: note.id});
          });
        };

        ctrl.updateNote = function(note) {
          if (_.isEmpty(note.workingData)) {
            return;
          }
          note.data = angular.copy(note.workingData);
          note.workingData = undefined;
          if (!_.isArray(note.data)) {
            note.data = [note.data];
          }

          // need to assign the update user from the local user profile because the API only returns IDs after the save.
          let updateUserDetails = function(note) {
            if (note.updateUser.id === localUser.userId) {
              note.updateUser.displayName = localUser.displayName;
              note.updateUser.name = localUser.name;
            }
          };

          note.$save().then(function() {
            updateUserDetails(note);

            let index = _.findIndex(ctrl.notes, ['id', note.id]);
            if (index > -1) {
              ctrl.notes[index] = note;
            }

            if (!_.find(ctrl.object[ctrl.param], ['id', note.id])) {
              ctrl.object[ctrl.param] = ctrl.object[ctrl.param] || [];
              ctrl.object[ctrl.param].push({id: note.id});
              ctrl.object.savePartial(ctrl.param);
            }
          });
        };
      };
    }
  });

export default MODULE_NAME;