/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import asset from './asset/asset';
import {HttpHelper} from "../../../util/http-helper";

const MODULE_NAME = "creative";

export const creativeFunctions = function($q, Creative, Asset) {
  Creative.create = function(defaults) {
    return new Creative(_.assign({
      assets: []
    }, defaults));
  };

  Creative.hydrate = function(creative) {
    if (!creative) {
      return $q.when(creative);
    }
    const promises = [];
    promises.push(Asset.fromCreative(creative.id).then(function(assets) {
      creative.assets = assets;
    }));
    const libraryCreativeId = _.get(creative.libraryCreative, "id", "");
    if (libraryCreativeId) {
      promises.push(Asset.fromCreative(libraryCreativeId).then(function(assets) {
        creative.libraryCreativeAssets = assets;
      }));
    }
    return $q.all(promises).then(function() {
      return creative;
    });
  };

  Creative.getHydrated = function(params) {
    return Creative.get(params).$promise.then(function(creative) {
      return Creative.hydrate(creative);
    });
  };

  Creative.getHydratedPreview = function(creativeId) {
    return Creative.get({id: creativeId}).$promise.then(function(creative) {
      const assetIds = _.map(_.filter(creative.layoutParameters, function(v) {
        return v.url;
      }), function(val) {
        return val.id;
      });
      if (assetIds.length < 1) {
        return creative;
      }
      return Asset.fromCreative(creativeId, assetIds).then(function(assets) {
        creative.assets = assets;
        return creative;
      });
    });
  };

  Creative.copyCreative = function(creative, savedLineItem, newId, userState, savedCreativeSet, transcodeConfig, dimensions, objectState) {
    if (!creative) {
      return $q.reject("Insufficient parameters for the creative copy");
    }
    return Creative.copy({
      id: _.isString(creative) ? creative : creative.id,
      creativeSetId: _.isString(savedCreativeSet) ? savedCreativeSet : _.get(savedCreativeSet, ['id']),
      lineItemId: _.isString(savedLineItem) ? savedLineItem : _.get(savedLineItem, ['id']),
      userState: userState || '',
      objectState: objectState || null,
      creativeId: newId || '',
      name: creative.name,
      transcodeConfig: transcodeConfig ? JSON.stringify(transcodeConfig) : "",
      dimensions: dimensions ? dimensions : null
    }).$promise;
  };
};

angular.module(MODULE_NAME, [asset])

  .factory('Creative', function($q, $http, adnResource, Asset) {
    const Creative = adnResource('creatives', {}, {
      copy: {
        params: {lineItemId: '@lineItemId', creativeSetId: '@creativeSetId', creativeId: '@creativeId', name: '@name', userState: '@userState', transcodeConfig: '@transcodeConfig', dimensions: '@dimensions', objectState: '@objectState'}
      },
      save: {
        transformRequest: HttpHelper.obtain($http).prependRequestTransform(function(creative) {
          if (!_.get(creative, ['lineItem', 'id'])) {
            delete creative.lineItem;
          }

          if (!_.get(creative, ['creativeSet', 'id'])) {
            delete creative.creativeSet;
          }
          return creative;
        })
      }
    });

    creativeFunctions($q, Creative, Asset);

    return Creative;
  });

export default MODULE_NAME;