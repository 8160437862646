/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';

const MODULE_NAME = "custom-event";

angular.module(MODULE_NAME, [])

  .factory("CustomEvent", function(adnResource) {
    return adnResource('customeventtypes', {}, {}, "customEvent");
  });

export default MODULE_NAME;