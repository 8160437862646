/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import {OBJECT_STATE, EXECUTION_STATUS} from "../../resources-constants";
import {Uuid} from "../../../../util/uuid";

const MODULE_NAME = "report-execution-resource";

import reportExecution from './report-execution';
import diagRequestExecution from './diagnostic-request-execution';
import {HttpHelper} from "../../../../util/http-helper";

angular.module(MODULE_NAME, [reportExecution, diagRequestExecution])

  .factory('adnExecutionResource', function(adnResource, $http) {
    return function(resourcePath, defaultParams, actions) {
      actions = _.merge({
        delete: {
          transformRequest: HttpHelper.obtain($http).prependRequestTransform(function(report) {
            return {
              id: report.id,
              cancelRequested: true,
              objectState: OBJECT_STATE.hidden
            };
          }),
          transformResponse: HttpHelper.obtain($http).appendResponseTransform(function(report) {
            return _.set(report, 'status', EXECUTION_STATUS.cancelled);
          })
        },
        cancel: {
          transformRequest: HttpHelper.obtain($http).prependRequestTransform(function(report) {
            return {
              id: report.id,
              cancelRequested: true
            };
          })
        }
      }, actions);

      let ExecResource = adnResource(resourcePath, defaultParams, actions);

      ExecResource.$start = function(params) {
        return ExecResource.save(_.assign({id: Uuid.generate()}, params)).$promise;
      };

      ExecResource.prototype.inProgress = function() {
        return this.isPending() || this.isQueued() || this.isProcessing() || this.isSubmitted();
      };

      ExecResource.prototype.isPending = function() {
        return this.status === EXECUTION_STATUS.pending;
      };

      ExecResource.prototype.isSubmitted = function() {
        return this.status === EXECUTION_STATUS.submitted;
      };

      ExecResource.prototype.isProcessing = function() {
        return this.status === EXECUTION_STATUS.processing;
      };

      ExecResource.prototype.isCancelled = function() {
        return this.status === EXECUTION_STATUS.cancelled || this.cancelRequested;
      };

      ExecResource.prototype.isCompleted = function() {
        return this.status === EXECUTION_STATUS.completed;
      };

      ExecResource.prototype.isRejected = function() {
        return this.status === EXECUTION_STATUS.rejected;
      };

      ExecResource.prototype.isFailed = function() {
        return this.status === EXECUTION_STATUS.failed;
      };

      ExecResource.prototype.isQueued = function() {
        return this.status === EXECUTION_STATUS.queued;
      };

      ExecResource.prototype.isTimedOut = function() {
        return this.status === EXECUTION_STATUS.timedOut;
      };

      ExecResource.prototype.isAwaiting = function() {
        return this.isPending() || this.isQueued() || this.isSubmitted() || this.isProcessing();
      };

      ExecResource.prototype.hasStarted = function() {
        return !(this.isPending() || this.isQueued() || this.isSubmitted());
      };

      ExecResource.prototype.hasFailed = function() {
        return this.isRejected() || this.isFailed() || this.isTimedOut();
      };

      ExecResource.prototype.hasCompleted = function() {
        return this.isCancelled() || this.isCompleted();
      };

      return ExecResource;
    };
  });

export default MODULE_NAME;