/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import template from './event-timer.html';

const MODULE_NAME = "event-timer";

angular.module(MODULE_NAME, [])

  .component('adnEventTimer', {
    template: template,
    controllerAs: 'ctrl',
    bindings: {
      timerObj: '<',
      timerWord: '@',
      timerStart: '@'
    },
    controller: function($timeout, $rootScope) {
      const ctrl = this;

      ctrl.$onInit = function() {
        let defaultRefreshTime = parseInt(ctrl.timerStart || 0) || 1;
        let refreshTime;
        ctrl.countDown = 0;
        ctrl.timerWord = ctrl.timerWord || 'statuses';

        let newExecutionsTimeout;
        let totalTimeTimeout;

        let destroyListener = $rootScope.$on('$stateChangeSuccess', function() {
          ctrl.timerObj.cancelTimers();
          destroyListener();
        });

        ctrl.timerObj.cancelTimers = function() {
          refreshTime = null;
          ctrl.countDown = 0;
          $timeout.cancel(newExecutionsTimeout);
          $timeout.cancel(totalTimeTimeout);
        };
        let totalTime = function(newCountdown) {
          ctrl.countDown = Math.max(newCountdown || ctrl.countDown - 1, 1);
          totalTimeTimeout = $timeout(function() {
            if (refreshTime >= defaultRefreshTime) {
              totalTime();
            }
          }, 1000);
        };

        ctrl.timerObj.setRefreshing = function(isRefreshing) {
          ctrl.isRefreshing = isRefreshing;
        };

        ctrl.timerObj.refreshTimers = function(suppliedTime) {
          if (suppliedTime) {
            defaultRefreshTime = suppliedTime;
            refreshTime = suppliedTime;
          } else if (!refreshTime) {
            refreshTime = defaultRefreshTime;
          } else {
            refreshTime = Math.min(Math.ceil(refreshTime + (refreshTime / 2)), 30);
          }
          $timeout.cancel(totalTimeTimeout);
          totalTime(refreshTime);
          $timeout.cancel(newExecutionsTimeout);
          newExecutionsTimeout = $timeout(function() {
            ctrl.timerObj.refreshEvent();
          }, refreshTime * 1000);
        };

        ctrl.timerObj.registered = ctrl.timerObj.registered || _.noop;
        ctrl.timerObj.registered();
      };
    }
  });

export default MODULE_NAME;