/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import moneySpacer from './money-spacer-directive';

import template from './money.html';

import localNetworkProfile from '../../../../components/session/local-network-profile';

const MODULE_NAME = 'money-directive';

angular.module(MODULE_NAME, [localNetworkProfile, moneySpacer])

  .directive('adnMoneyFormControl', function(LocalNetworkProfile, localUserProfile) {
    return {
      restrict: 'A',
      scope: {
        model: '=adnMoneyFormControl',
        name: '@adnName',
        blockSubmit: '<ngDisabled',
        isRequired: '<',
        changeFunc: '<?',
        updateCurrencyHook: '<?',
        defaultCurrency: '<?',
        availableCurrencies: '<',
        heavyFormat: '<',
        fixedWidth: '<'
      },
      template: template,
      link: function(scope) {
        scope.currencies = _.isArray(scope.availableCurrencies) ? scope.availableCurrencies : LocalNetworkProfile.get('availableCurrencies');
        if (!_.isObject(scope.model)) {
          scope.model = {};
        }

        function updateCurrency(theCurrency) {
          const currency = _.get(scope.model, 'currency');
          const amount = _.get(scope.model, 'amount');
          if (!currency || (!_.isFinite(amount))) {
            const defaultCurrency = theCurrency || scope.defaultCurrency || LocalNetworkProfile.get('defaultCurrency');
            const preferredCurrency = localUserProfile.getPreferredCurrency();
            const selCurrency = preferredCurrency && _.indexOf(scope.currencies, preferredCurrency) > -1 ? preferredCurrency : _.indexOf(scope.currencies, defaultCurrency) > -1 ? defaultCurrency : scope.currencies[0];
            _.set(scope.model, 'currency', selCurrency);
          } else {
            if (_.indexOf(scope.currencies, currency) < 0) {
              scope.currencies.push(currency);
            }
          }
        }

        updateCurrency();

        if (_.isFunction(scope.changeFunc)) {
          scope.changeFunc = {change: scope.changeFunc};
        } else {
          scope.changeFunc = {change: _.noop};
        }

        if (_.isObject(scope.updateCurrencyHook)) {
          scope.updateCurrencyHook.update = function(theCurrency) {
            updateCurrency(theCurrency);
          };
        }
      }
    };
  });

export default MODULE_NAME;