type JoinInfo = {
  [name: string]: {
    table: string;
    field: string;
    joinField: string;
    whereField: string;
    translation: string;
  };
};

export const JOIN_INFO: JoinInfo = {
  Advertiser: {
    table: 'advertiser',
    field: 'advertiserId',
    joinField: 'advertiser.id',
    whereField: 'advertiser.name',
    translation: 'Advertiser'
  },
  ReadOnlyAdvertiser: {
    table: 'advertiser',
    field: 'readOnlyAdvertiserId',
    joinField: 'advertiser.id',
    whereField: 'advertiser.name',
    translation: 'Advertiser'
  },
  Order: {
    table: 'order',
    field: 'orderId',
    joinField: 'order.id',
    whereField: 'order.name',
    translation: 'Order'
  },
  Team: {
    table: 'team',
    field: 'teamId',
    joinField: 'team.id',
    whereField: 'team.name',
    translation: 'Team'
  },
  OwnerTeam: {
    table: 'team',
    field: 'ownerTeamId',
    joinField: 'team.id',
    whereField: 'team.name',
    translation: 'Team'
  },
  Salesperson: {
    table: 'user',
    field: 'salespersonId',
    joinField: 'user.id',
    whereField: 'user.name',
    translation: 'Salesperson'
  },
  AdOps: {
    table: 'user',
    field: 'adOpsId',
    joinField: 'user.id',
    whereField: 'user.name',
    translation: 'Ad Ops User'
  },
  Tier: {
    table: 'tier',
    field: 'tierId',
    joinField: 'tier.id',
    whereField: 'tier.name',
    translation: 'Tier'
  },
  Folio: {
    table: 'folio',
    field: 'folioId',
    joinField: 'folio.id',
    whereField: 'folio.name',
    translation: 'Folio'
  },
  CreativeSet: {
    table: 'creative_set',
    field: 'creativeSetId',
    joinField: 'creative_set.id',
    whereField: 'creative_set.name',
    translation: 'Creative Set'
  },
  Creative: {
    table: 'creative',
    field: 'creativeId',
    joinField: 'creative.id',
    whereField: 'creative.name',
    translation: 'Creative'
  },
  LineItem: {
    table: 'line_item',
    field: 'lineItemId',
    joinField: 'line_item.id',
    whereField: 'line_item.name',
    translation: 'Line Item'
  },
  Layout: {
    table: 'layout',
    field: 'layoutId',
    joinField: 'layout.id',
    whereField: 'layout.name',
    translation: 'Layout'
  },
  SiteGroup: {
    table: 'site_group',
    field: 'siteGroupId',
    joinField: 'site_group.id',
    whereField: 'site_group.name',
    translation: 'Site Group'
  },
  EarningsAccount: {
    table: 'earnings_account',
    field: 'earningsAccountId',
    joinField: 'earnings_account.id',
    whereField: 'earnings_account.name',
    translation: 'EarningsAccount'
  },
  Site: {
    table: 'site',
    field: 'siteId',
    joinField: 'site.id',
    whereField: 'site.name',
    translation: 'Site'
  }
};
