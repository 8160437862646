/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import uiRouter from '@uirouter/angularjs';

import resources from '../../../components/api/resources/resources';
import adnChecker from "../../../components/util/adn-checker";

const MODULE_NAME = "scan-results-ctrl";

angular.module(MODULE_NAME, [
  uiRouter,
  resources,
  adnChecker,
])
  .controller('ScanResultsCtrl', function(adnChecker) {
    const ctrl = this;

    ctrl.hide = {};

    ctrl.doCheck = function() {
      ctrl.loaded = false;

      adnChecker.scanResultsByCreative().then(function(data) {
        ctrl.creativeBlocks = data;
      });

      adnChecker.scanResults().then(function(data) {
        ctrl.loaded = true;

        ctrl.data = data;
      });
    };
    ctrl.doCheck();
  });

export default MODULE_NAME;