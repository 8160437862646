export const TOOLBAR_BUTTONS: Array<Array<string>> = [
  ['h1', 'h2', 'h3', 'p'],
  ['bold', 'italics', 'strikeThrough', 'ul', 'ol', 'redo', 'undo', 'clear'],
  ['insertImage', 'insertLink', 'html']
];

export const NOTES_BUTTONS: Array<Array<string>> = [
  ['h2', 'h3', 'h4', 'p'],
  ['bold', 'italics', 'strikeThrough', 'ul', 'ol', 'redo', 'undo', 'clear'],
  ['insertImage', 'insertLink', 'html']
];