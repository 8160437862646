/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import uiRouter from '@uirouter/angularjs';
import {getRegisteredUrl} from "../../../config/registered-urls";

const MODULE_NAME = "state-title-directive";

angular.module(MODULE_NAME, [uiRouter])

  .directive('adnStateTitle', function($interpolate, $state, $window, LocalNetworkProfile) {
    return {
      restrict: 'A',
      template: '{{ctrl.title}}',
      scope: {},
      bindToController: {
        prefix: '@',
        suffix: '@',
        separator: '@',
        stateDataProperty: '@'
      },
      controllerAs: 'ctrl',
      controller: function($scope) {
        const ctrl = this;

        ctrl.$onInit = function() {
          ctrl.separator = ctrl.separator || ' - ';
          let dataPropertyPath = 'data.' + (ctrl.stateDataProperty || 'title');

          let updateTitle = function() {
            let currentState = $state.$current,
              dataProperty = _.get(currentState, dataPropertyPath, ''),
              interpolateContext = _.get(currentState, ['locals', 'globals'], currentState),
              title = $interpolate(dataProperty)(interpolateContext),
              processedTitle = LocalNetworkProfile.getNetworkTranslations(title) || title;

            const objectName = _.get(currentState, ['locals', 'title@app', 'model', 'name']);
            const regUrl = getRegisteredUrl($window);
            let suffix = _.get(regUrl, 'pageTitle');
            suffix = (ctrl.hideSuffix || !suffix) ? ctrl.suffix : suffix;
            ctrl.title = _.compact([ctrl.prefix, objectName, processedTitle, suffix]).join(ctrl.separator);
          };

          if ($state.$current.name) {
            updateTitle();
          }

          $scope.$on('$stateChangeSuccess', function() {
            updateTitle();
          });
        };
      }
    };
  });

export default MODULE_NAME;