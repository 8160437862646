import * as _ from 'lodash';

export const CURRENCY_TO_LANGUAGE = {
  DKK: ['da', 'dan'],
  NOK: ['nb', 'no', 'nn', 'nor', 'nno', 'nob'],
  SEK: ['sv', 'swe']
};

export const ADN_TARGETING_TYPES = {
  publisher: {
    id: 'publisher',
    title: 'Publisher',
    targets: 'publisherTarget',
    widget: 'adnPublisherTargeting',
    summaryWidget: 'adnTargetingPublisherSummary',
    icon: 'fa-handshake-o',
    templateType: 'TARGETING_PUBLISHER',
    apiProp: 'earningsAccounts',
    apiType: 'PUBLISHER'
  },
  adUnit: {
    id: 'adUnit',
    title: 'Ad Unit',
    targets: 'adUnitTarget',
    widget: 'adnAdUnitTargeting',
    summaryWidget: 'adnTargetingAdUnitSummary',
    icon: 'fa-newspaper-o',
    templateType: 'TARGETING_AD_UNIT',
    apiProp: 'adUnits',
    apiType: 'AD_UNIT'
  },
  site: {
    id: 'site',
    title: 'Site',
    targets: 'siteTarget',
    widget: 'adnSiteTargeting',
    summaryWidget: 'adnTargetingSiteTargetSummary',
    icon: 'fa-sitemap',
    templateType: 'TARGETING_SITE',
    apiProp: 'sites',
    apiType: 'SITE'
  },
  siteGroup: {
    id: 'siteGroup',
    title: 'Site Group',
    targets: 'siteGroupTarget',
    widget: 'adnSiteGroupTargeting',
    summaryWidget: 'adnTargetingSiteGroupTargetSummary',
    icon: 'fa-umbrella',
    templateType: 'TARGETING_SITE_GROUP',
    apiProp: 'siteGroups',
    apiType: 'SITE_GROUP'
  },
  siteCountry: {
    id: 'siteCountry',
    title: 'Site Country',
    targets: 'siteCountryTarget',
    widget: 'adnSiteCountryTargeting',
    summaryWidget: 'adnTargetingSiteCountryTargetSummary',
    icon: 'fa-flag',
    templateType: 'TARGETING_SITE_COUNTRY',
    apiProp: 'countries',
    apiType: 'SITE_COUNTRY'
  },
  adUnitMatchingLabel: {
    id: 'adUnitMatchingLabel',
    title: 'Ad Unit Matching Label',
    targets: 'adUnitMatchingLabelTargets',
    widget: 'adnAdUnitMatchingLabelTargeting',
    summaryWidget: 'adnAdUnitMatchingLabelTargetSummary',
    icon: 'fa-tag',
    apiProp: 'matchingLabels',
    templateType: 'TARGETING_AD_UNIT_MATCHING_LABEL',
    targetingLength: true,
    apiType: 'AD_UNIT_MATCHING_LABEL'
  },
  thirdPartyAudience: {
    id: 'thirdPartyAudience',
    title: 'Third-Party Audience',
    targets: 'thirdPartyAudienceTargets',
    widget: 'adnThirdPartyAudienceTargeting',
    summaryWidget: 'adnThirdPartyAudienceTargetSummary',
    icon: 'fa-database',
    templateType: 'TARGETING_THIRD_PARTY_AUDIENCE',
    apiProp: 'thirdPartyAudiences',
    targetingLength: true,
    bulkBlocked: true,
    apiType: 'THIRD_PARTY_AUDIENCE'
  },
  semantic: {
    id: 'semantic',
    title: 'Semantic',
    targets: 'semanticTargets',
    widget: 'adnSemanticTargeting',
    summaryWidget: 'adnTargetingSemanticSummary',
    icon: 'fa-commenting',
    apiProp: 'sentence',
    templateType: 'TARGETING_SEMANTIC',
    targetingLength: true,
    apiType: 'SEMANTIC_CATEGORY'
  },
  userSegment: {
    id: 'userSegment',
    title: 'Segment',
    targets: 'userSegmentTargets',
    widget: 'adnSegmentTargeting',
    summaryWidget: 'adnTargetingSegmentSummary',
    icon: 'fa-users',
    apiProp: 'userSegments',
    templateType: 'TARGETING_USER_SEGMENT',
    targetingLength: true,
    apiType: 'USER_SEGMENT'
  },
  category: {
    id: 'category',
    title: 'Category',
    targets: 'categoryTargets',
    widget: 'adnCategoryTargeting',
    summaryWidget: 'adnTargetingCategorySummary',
    icon: 'fa-address-card-o',
    apiProp: 'categories',
    templateType: 'TARGETING_CATEGORY',
    targetingLength: true,
    checkComplex: true,
    apiType: 'CATEGORY'
  },
  geospatial: {
    id: 'geospatial',
    title: 'Geospatial Area',
    targets: 'geospatialTargets',
    widget: 'adnGeospatialTargeting',
    summaryWidget: 'adnGeospatialTargetingSummary',
    icon: 'fa-globe',
    templateType: 'TARGETING_GEOSPATIAL',
    targetingLength: true,
    apiType: 'GEOSPATIAL'
  },
  namedLocation: {
    id: 'namedLocation',
    title: 'Location',
    targets: 'namedLocationTarget',
    widget: 'adnNamedLocationTargeting',
    summaryWidget: 'adnTargetingNamedLocationSummary',
    icon: 'fa-map-marker',
    templateType: 'TARGETING_NAMED_LOCATION',
    apiProp: 'locations',
    apiType: 'NAMED_LOCATION'
  },
  device: {
    id: 'device',
    title: 'Device',
    targets: 'deviceTargets',
    widget: 'adnDeviceTargeting',
    summaryWidget: 'adnTargetingDeviceSummary',
    icon: 'fa-tablet',
    templateType: 'TARGETING_DEVICE',
    apiProp: 'devices',
    targetingLength: true,
    apiType: 'DEVICE_PROPERTY'
  },
  keyword: {
    id: 'keyword',
    title: 'Keyword',
    targets: 'keywordTargets',
    widget: 'adnKeywordTargeting',
    summaryWidget: 'adnTargetingKeywordSummary',
    icon: 'fa-book',
    templateType: 'TARGETING_KEYWORD',
    apiProp: 'keywords',
    targetingLength: true,
    checkComplex: true,
    apiType: 'KEYWORD'
  },
  keyValue: {
    id: 'keyValue',
    title: 'Key-Value',
    targets: 'keyValueTargets',
    widget: 'adnKeyValueTargeting',
    summaryWidget: 'adnTargetingKeyValueSummary',
    icon: 'fa-keyboard-o',
    templateType: 'TARGETING_KEY_VALUE',
    apiProp: 'entries',
    targetingLength: true,
    apiType: 'KEY_VALUE'
  },
  viewability: {
    id: 'viewability',
    title: 'Viewability',
    targets: 'viewabilityTarget',
    widget: 'adnViewabilityTargeting',
    templateType: 'TARGETING_VIEWABILLITY',
    summaryWidget: 'adnTargetingViewabilitySummary',
    icon: 'fa-eye',
    apiProp: 'viewability',
    apiType: 'AD_UNIT_VIEWABILITY'
  },
  retargeting: {
    id: 'retargeting',
    title: 'Retargeting',
    targets: 'retargetingTargets',
    widget: 'adnRetargetingTargeting',
    summaryWidget: 'adnTargetingRetargetingSummary',
    icon: 'fa-bullseye',
    templateType: 'TARGETING_RETARGETING',
    apiProp: 'entries',
    targetingLength: true,
    apiType: 'RETARGETING'
  },
  dayParting: {
    id: 'dayParting',
    title: 'Day Parting',
    targets: 'dayPartingTargets',
    widget: 'adnDayPartingTargeting',
    summaryWidget: 'adnTargetingDayPartingSummary',
    templateType: 'TARGETING_DAY_PARTING',
    icon: 'fa-calendar',
    targetingLength: true,
    apiType: 'DAY_PARTING'
  },
  date: {
    id: 'date',
    title: 'Date',
    targets: 'dateTarget',
    widget: 'adnDateTargeting',
    summaryWidget: 'adnTargetingDateTargetingSummary',
    icon: 'fa-clock-o',
    templateType: 'TARGETING_DATE_RANGES',
    apiProp: 'dateRanges',
    params: {lineItemStartDate: null},
    apiType: 'DATE'
  },
  url: {
    id: 'url',
    title: 'URL',
    targets: 'articleTarget',
    widget: 'adnUrlTargeting',
    summaryWidget: 'adnTargetingUrlSummary',
    templateType: 'TARGETING_URL',
    icon: 'fa-external-link',
    apiProp: 'urls',
    apiType: 'ARTICLE'
  },
  domain: {
    id: 'domain',
    title: 'Domain Name',
    targets: 'domainNameTarget',
    widget: 'adnDomainTargeting',
    summaryWidget: 'adnTargetingDomainSummary',
    templateType: 'TARGETING_DOMAIN',
    icon: 'fa-feed',
    apiProp: 'names',
    apiType: 'DOMAIN_NAME'
  },
  ipAddress: {
    id: 'ipAddress',
    title: 'IP Address',
    targets: 'ipAddressTarget',
    widget: 'adnIpAddressTargeting',
    templateType: 'TARGETING_IP_ADDRESS',
    summaryWidget: 'adnTargetingIpAddressSummary',
    icon: 'fa-wifi',
    apiProp: 'addresses',
    apiType: 'IP_ADDRESS'
  },
};

export function getTargetingType(apiTargetType: string) {
  return _.find(ADN_TARGETING_TYPES, function (val) {
    return val.apiType === apiTargetType;
  });
}

export const ALL_TARGETING_TYPES = _.assign({}, ADN_TARGETING_TYPES);

export const SPECIAL_NO_CATEGORY_TARGET = "target|absence|of|categories";