/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';

const MODULE_NAME = "report-execution";

angular.module(MODULE_NAME, [])

  .factory('ReportExecution', function(adnExecutionResource) {
    let ReportExecution = adnExecutionResource('reports');

    ReportExecution.start = function(params) {
      return ReportExecution.$start(params);
    };

    return ReportExecution;
  });

export default MODULE_NAME;