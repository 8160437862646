/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';

const MODULE_NAME = "creative-preview";

angular.module(MODULE_NAME, [])

  .factory('CreativePreview', function(adnResource) {
    return adnResource('creativepreview');
  });

export default MODULE_NAME;