/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import uiBootstrap from 'angular-ui-bootstrap';
import translate from 'angular-translate';
import _ from 'lodash';
import {OBJECT_STATE} from "../../../components/api/resources/resources-constants";
import nextReportComponent from "../../report-schedule/next-report-component";
import reportUrlComponent from "../../report-schedule/report-url-component";

import template from './report-schedules.html';
import recipientsModalTemplate from '../../report-schedule/recipients-modal.html';

import reportSchedulesFormTemplate from './report-schedules-form.html';

import resources from '../../../components/api/resources/resources';
import searchSelect from '../../common/controller/search-select-component';
import dateSelector from '../../common/directives/form-controls/date-selector';
import reportHistoryComp from '../../report/report-history-component';
import {ADN_REPORT_TYPES} from '../../report/report-types';
import {EmailHelper} from "../../../components/util/email";
import {Uuid} from "../../../components/util/uuid";
import localNetworkProfile from '../../../components/session/local-network-profile';
import {afterSaveAfterInitMods, beforeSubmitMods} from "../../report-schedule/report-schedule-controller";

const MODULE_NAME = 'report-scheduler';

angular.module(MODULE_NAME, [
  translate,
  uiBootstrap,
  resources,
  reportHistoryComp,
  dateSelector,
  searchSelect,
  localNetworkProfile,
  nextReportComponent,
  reportUrlComponent
])

  .directive('adnReportSchedules', function($templateCache, $uibModal, adnTimeShifter, User, Order, ReportSchedule, ReportTemplate, localUserProfile, LocalNetworkProfile) {
    return {
      restrict: 'A',
      scope: {
        obj: '<adnReportSchedules',
        reportType: '@adnReportType',
        currency: '<'
      },
      template: template,
      link: function(scope) {
        scope.reportType = scope.reportType || 'LINE_ITEM';
        let adnReportType = ADN_REPORT_TYPES[scope.reportType];

        $templateCache.put('reportSchedulesForm.html', reportSchedulesFormTemplate);

        scope.availableCurrencies  = LocalNetworkProfile.get('availableCurrencies');
        const preferredCurrency = localUserProfile.getPreferredCurrency();

        let combineEmails = function(dataObj) {
          dataObj.recipientEmailAddressesCombined = (dataObj.recipientEmailAddresses || []).join(', ');
        };

        ReportTemplate.query().$promise.then(function(page) {
          scope.reportTemplates = _.filter(page.results, {reportType: adnReportType.id});
          scope.lineItemReportTemplates = _.filter(page.results, {reportType: 'LINE_ITEM'});
        });

        ReportSchedule.query({relatedId: scope.obj.id, relatedObjectType: adnReportType.objType}, function(page) {
          scope.reportSchedules = _.filter(page.results, function(schedule) {
            combineEmails(schedule);
            return _.endsWith(_.get(schedule.parameters, adnReportType.idKey), scope.obj.id);
          });

          let recipUsers = _.uniq(_.flatten(_.map(_.filter(scope.reportSchedules, function(rs) {
            return _.isArray(rs.recipientUsers) && rs.recipientUsers.length > 0;
          }), 'recipientUsers')));
          if (recipUsers.length > 0) {
            User.query({ids: _.map(recipUsers, 'id'), includeHidden: true, includeInactive: true}, function(page) {
              let allUsers = page.results;

              _.forEach(scope.reportSchedules, function(rs) {
                _.forEach(rs.recipientUsers, function(ru) {
                  let match = _.find(allUsers, ['id', ru.id]);
                  if (match) {
                    ru.displayName = match.displayName;
                  }
                });
              });
            });
          }
        });

        scope.editRecipients = function(repSchedule) {
          let modalInstance = $uibModal.open({
            template: recipientsModalTemplate,
            controllerAs: 'modalCtrl',
            size: 'lg',
            controller: /*@ngInject*/ function() {
              const modalCtrl = this;

              if (_.get(scope.obj, 'order.id')) {
                Order.get({id: scope.obj.order.id}, function(order) {
                  modalCtrl.salespersonId = _.get(order, 'salespersonUser.id');
                  modalCtrl.adOpsId = _.get(order, 'adOpsUser.id');
                });
              }

              User.query({minimal: true}, function(page) {
                modalCtrl.allUsers = page.results;
              });

              modalCtrl.selUsers = _.map(repSchedule.recipientUsers, 'id');

              modalCtrl.save = function() {
                repSchedule.recipientUsers = _.filter(_.map(modalCtrl.selUsers, function(su) {
                  return _.find(modalCtrl.allUsers, ['id', su]);
                }), function(su) {
                  return su && !!su.id;
                });

                if (!repSchedule.isNew) {
                  repSchedule.savePartial('recipientUsers').then(function(savedSchedule) {
                    _.set(repSchedule, 'validationWarnings', savedSchedule.validationWarnings);
                    modalInstance.close({success: true});
                  }, function() {
                    modalInstance.close({success: false});
                  });
                } else {
                  modalInstance.close();
                }
              };
            }
          });
          modalInstance.result.then(function(result) {
            repSchedule.recipientEmailsSaved = result;
          });
        };

        scope.schedulePeriods = adnReportType.periods;

        const timeZone = LocalNetworkProfile.get('timeZone') || 'utc';
        const scheduleLocale = localUserProfile.get('locale') || 'en_GB';

        scope.newReportSchedule = function() {
          scope.reportSchedule = ReportSchedule.create({
            id: Uuid.generate(),
            timeZone: LocalNetworkProfile.get('timeZone') || 'UTC',
            currency: scope.currency || preferredCurrency || LocalNetworkProfile.get('defaultCurrency') || 'EUR',
            locale: localUserProfile.get('locale') || 'en_GB',
            period: adnReportType.defaultPeriod.id,
            isNew: true
          });

          afterSaveAfterInitMods(scope.reportSchedule, adnTimeShifter, timeZone, scheduleLocale);
        };
        scope.newReportSchedule();

        scope.deleteSchedule = function(reportSchedule) {
          reportSchedule.objectState = OBJECT_STATE.hidden;
          reportSchedule.$save();
        };

        scope.editSchedule = function(reportSchedule) {
          if (!reportSchedule.modOptions) {
            afterSaveAfterInitMods(reportSchedule, adnTimeShifter, timeZone, scheduleLocale);
          }
        };

        scope.updateSchedule = function(reportSchedule) {
          let workingSchedule = reportSchedule ? reportSchedule : scope.reportSchedule;

          workingSchedule.parameters = {};
          workingSchedule.parameters[adnReportType.idKey] = scope.obj.id;
          if (adnReportType === ADN_REPORT_TYPES.LINE_ITEM) {
            workingSchedule.parameters.relatedLineItems = [{id: scope.obj.id}];
          }

          workingSchedule.recipientEmailAddresses = _.filter(_.map((workingSchedule.recipientEmailAddressesCombined || "").split(','), function(email) {
            return _.trim(email);
          }), function(email) {
            return email.length > 0 && EmailHelper.isEmail(email);
          });

          beforeSubmitMods(workingSchedule);

          workingSchedule.$save().then(function(schedule) {
            afterSaveAfterInitMods(schedule, adnTimeShifter, timeZone, scheduleLocale);
            if (reportSchedule) {
              let index = _.findIndex(scope.reportSchedules, ['id', reportSchedule.id]);
              if (index > -1) {
                scope.reportSchedules[index] = schedule;
                combineEmails(scope.reportSchedules[index]);
              }
            } else {
              scope.newReportSchedule();
              combineEmails(schedule);
              scope.reportSchedules.unshift(schedule);
            }
          });
        };
      }
    };
  });

export default MODULE_NAME;