/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';

const MODULE_NAME = "report-component";

angular.module(MODULE_NAME, [])

  .factory("ReportComponent", function(adnResource) {
    return adnResource('reportcomponents', {}, {}, 'reportComponent');
  });

export default MODULE_NAME;