import * as _ from 'lodash';

export class UrlParamsEncoder {
  static encode(obj, prefixFirst: boolean): string {
    const pairs = [];
    _.forEach(obj, function(val, prop) {
      const encodedPropEquals = encodeURIComponent(prop) + '=';
      if (_.isArray(val)) {
        _.forEach(val, function(v) {
          pairs.push(encodedPropEquals + encodeURIComponent(_.isObject(v) ? JSON.stringify(v) : v));
        });
      } else if (_.isObject(val)) {
        pairs.push(encodedPropEquals + encodeURIComponent(JSON.stringify(val)));
      } else {
        if ((val || val === 0) && (!_.isString(val) || _.trim(val).length > 0)) {
          pairs.push(encodedPropEquals + encodeURIComponent(val));
        }
      }
    });
    if (pairs.length === 0) {
      return '';
    }
    const urlParams = pairs.join('&');
    return prefixFirst ? '&' + urlParams : urlParams;
  }
}
