/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';

const MODULE_NAME = "network";

angular.module(MODULE_NAME, [])

  .factory("NetworkInfo", function(adnResource) {
    return adnResource('networkinfo', {}, {}, 'networkInfo');
  })

  .factory("Network", function(adnResource) {
    return adnResource('networks');
  });

export default MODULE_NAME;