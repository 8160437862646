/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import uiBootstrap from 'angular-ui-bootstrap';
import translate from 'angular-translate';
import ngSanitize from 'angular-sanitize';
import moment from 'moment';

import template from './notification-tab.html';

import resources from '../../../components/api/resources/resources';
import localNetworkProfile from '../../../components/session/local-network-profile';
import objectTypeParamsFilter from '../../common/filters/object-type-state-params-filter';
import messageFilter from '../../common/filters/message-params-translation-filter';

const MODULE_NAME = 'notification-tab-directive';

const NOTIFICATION_SYNC_INTERVAL = 45000;

angular.module(MODULE_NAME, [translate, ngSanitize, uiBootstrap, resources, localNetworkProfile, messageFilter, objectTypeParamsFilter])

  .controller('adnNotificationsTabCtrl', function($scope, $timeout, $interval, Notification, UserProfile, localUserProfile, LocalNetworkProfile, adnMessageParamsFilter) {
    let networkId = LocalNetworkProfile.getNetworkId(),
      timer,
      ctrl = this,
      localUser = localUserProfile.get(),
      updateNotifications = function() {
        const profile = localUserProfile.getProfile();
        const timeSince = _.get(profile, ['adnNotificationsSince', networkId]);
        Notification.query(timeSince ? moment(timeSince) : undefined, function(notifications) {
          if (notifications.length !== ctrl.notifications.length || (notifications.length > 0 && !notifications[0].equals(ctrl.notifications[0]))) {
            $timeout(function() {
              $scope.$applyAsync(function() {
                ctrl.parentMethodHook.unread = 0;
                ctrl.notifications = _.map(notifications, function(notification) {
                  const timeRead = _.get(profile, ['adnNotificationsRead', networkId]);
                  notification.read = timeRead ? !notification.time.isAfter(timeRead) : false;
                  notification.message = adnMessageParamsFilter(notification.message);
                  if (!notification.read) {
                    ctrl.parentMethodHook.unread++;
                  }
                  return notification;
                });
              });
            });
          }
        });
      },
      stopTimer = function() {
        $interval.cancel(timer);
      },
      startTimer = function() {
        timer = $interval(updateNotifications, NOTIFICATION_SYNC_INTERVAL, 0, false);
        updateNotifications();
      },
      getValidDate = function(unverifiedDate) {
        let sinceDate = unverifiedDate;
        if (_.isString(sinceDate)) {
          sinceDate = moment(sinceDate);
        }
        if (!sinceDate || !moment(sinceDate).isValid()) {
          // set up a default date if nothing valid is obtained -- currently using a very old date for working with bootstrap data.
          sinceDate = moment.utc().subtract(1, 'year');
        }
        return sinceDate;
      },
      updateProfile = function() {
        let profile = localUserProfile.getProfile();
        UserProfile.save({
          id: localUser.userId,
          profile: _.pick(profile, ['adnNotificationsSince', 'adnNotificationsRead'])
        }, function(apiUser) {
          localUser = localUserProfile.safeSaveProfile(['adnNotificationsSince', networkId], getValidDate(_.get(apiUser, ['profile', 'adnNotificationsSince', networkId])));
          localUser = localUserProfile.safeSaveProfile(['adnNotificationsRead', networkId], getValidDate(_.get(apiUser, ['profile', 'adnNotificationsRead', networkId])));
        });
      };

    ctrl.notifications = [];

    ctrl.$onInit = function() {
      function open() {
        ctrl.parentMethodHook.unread = 0;
        localUserProfile.safeSaveProfile(['adnNotificationsRead', networkId], moment());
        updateProfile();
        _.forEach(ctrl.notifications, function(notification) {
          notification.read = true;
        });
      }
      ctrl.parentMethodHook.notificationsOn = function() {
        open();
      };
    };

    startTimer();

    ctrl.clear = function($event) {
      $event.stopPropagation();

      localUserProfile.safeSaveProfile(['adnNotificationsSince', networkId], moment());
      updateProfile();
      ctrl.notifications = [];
      ctrl.parentMethodHook.unread = 0;
    };

    ctrl.getColorLabel = function(notification, prefix) {
      // TODO: this is a temporary measure for demo purposes. Should fix properly when the time comes.
      if (~notification.message.code.indexOf("fail")) {
        return prefix + "warning";
      }
      if (~notification.message.code.indexOf("pass")) {
        return prefix + "success";
      }
      if (~notification.message.code.indexOf("live")) {
        return prefix + "success";
      }
      return prefix + notification.type.toLowerCase();
    };

    $scope.$on('$destroy', stopTimer);
    $scope.$on('event:auth-loginRequired', stopTimer);
    $scope.$on('event:auth-loginConfirmed', startTimer);
  })
  .directive('adnNotificationsTab', function() {
    return {
      restrict: 'A',
      require: '^adnSidebarTabset',
      template: template,
      scope: {},
      bindToController: {
        parentMethodHook: '<'
      },
      controllerAs: 'ctrl',
      controller: 'adnNotificationsTabCtrl'
    };
  });

export default MODULE_NAME;