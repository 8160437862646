/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';

import localNetworkProfile from '../../../../components/session/local-network-profile';
import _ from "lodash";

const MODULE_NAME = 'currency-directive';

angular.module(MODULE_NAME, [localNetworkProfile])

  .component('adnCurrencyFormControl', {
    bindings: {
      model: '<',
      param: '@',
      name: '@adnName',
      availableCurrencies: '<'
    },
    template: `
        <div>
          <div>
            <select ng-if="ctrl.currencies.length > 1" class="form-control" name="{{name}}.currency" ng-model="ctrl.model[ctrl.param]"
                    ng-options="currency as currency for currency in ctrl.currencies track by currency" adn-form-control style="width: auto;">
                <option value="">--</option>
            </select>
          </div>
        </div>
      `,
    controllerAs: 'ctrl',
    controller: function(LocalNetworkProfile) {
      const ctrl = this;

      ctrl.$onInit = function() {
        if (ctrl.availableCurrencies) {
          ctrl.currencies = ctrl.availableCurrencies;
        } else {
          const defaultCurrency = LocalNetworkProfile.get('defaultCurrency');
          ctrl.currencies = _.orderBy(LocalNetworkProfile.get('availableCurrencies'), function(curr) {
            return curr === defaultCurrency ? 0 : 1;
          });
        }
      };
    }
  });

export default MODULE_NAME;