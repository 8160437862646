/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';

import {HttpHelper} from '../../../util/http-helper';

const MODULE_NAME = "targeting-template";

angular.module(MODULE_NAME, [])
  .factory("TargetingTemplate", function(adnResource, $http) {
    return adnResource('targetingtemplates', {}, HttpHelper.obtain($http).transformJsonActions('data'));
  });

export default MODULE_NAME;