/**
 * Copyright © 2024 Adnuntius AS.
 */
import * as _ from 'lodash';

export const OBJECT_TYPE: any = {
  AdUnit: {name: 'Ad Unit', micon: 'miconAdUnit', perm: ['MANAGE_PUBLISHING', 'AX_PUBLISHER_ADMIN', 'AX_PUBLISHER'], stateName: 'app.ad-units.ad-unit', searchType: 'au', type: 'AdUnit', apiParam: 'adUnit', caps: 'AD_UNIT'},
  Advertiser: {name: 'Advertiser', perm: ['MANAGE_BUSINESS', 'AX_ADVERTISER'], micon: 'miconAdvertiser', childType: 'Order', stateName: 'app.advertisers.advertiser', searchType: 'av', type: 'Advertiser', apiParam: 'advertiser', caps: 'ADVERTISER'},
  Asset: {name: 'Asset', type: 'Asset', apiParam: 'asset', caps: 'ASSET'},
  ApiKey: {name: 'API Key', stateName: 'app.api-keys.api-key', type: 'ApiKey', apiParam: 'api-key', caps: 'API_KEY'},
  LineItem: {name: 'Line Item', perm: 'MANAGE_ADVERTISING', sectionPerm: 'lineItems', micon: 'miconLineItem', childType: 'Creative', stateName: 'app.line-items.line-item', stateNameAlts: ['app.campaigns.campaign'], type: 'LineItem', searchType: 'li', apiParam: 'lineItem', idParams: ['deduplicationLevel'], caps: 'LINE_ITEM'},
  CreativeSet: {name: 'Creative Set', perm: 'MANAGE_ADVERTISING', sectionPerm: 'creativeSets', micon: 'miconCreativeSet', childType: 'Creative', stateName: 'app.creative-sets.creative-set', type: 'CreativeSet', searchType: 'cs', apiParam: 'creativeSet', caps: 'CREATIVE_SET'},
  LineItemTemplate: {name: 'Line Item Template', perm: 'MANAGE_ADVERTISING', sectionPerm: 'lineItemTemplates', paramOnResponse: 'lineItemTemplates', micon: 'miconLineItem', stateName: 'app.line-item-templates.line-item-template', type: 'LineItemReview', searchType: 'lit', apiParam: 'lineItemTemplate', idParams: ['deduplicationLevel'], caps: 'LINE_ITEM_TEMPLATE'},
  LineItemReview: {name: 'Line Item Review', perm: 'MANAGE_ADVERTISING', sectionPerm: 'lineItems', paramOnResponse: 'lineItem', micon: 'miconLineItem', childType: 'Creative', stateName: 'app.reviews.review', stateNameAlts: ['app.campaigns.campaign', 'app.reviews.review', 'app.ax-line-items.ax-line-item', 'app.ax-reviews.ax-review'], type: 'LineItemReview', searchType: 'li', apiParam: 'lineItem', idParams: ['deduplicationLevel'], caps: 'LINE_ITEM'},
  Campaign: {name: 'Self-Service Campaign', perm: 'MANAGE_ADVERTISING', sectionPerm: 'lineItems', micon: 'miconLineItem', childType: 'Creative', stateName: 'app.reviews.review', stateNameAlts: ['app.campaigns.campaign', 'app.reviews.review'], type: 'LineItemReview', searchType: 'li', apiParam: 'lineItem', idParams: ['deduplicationLevel'], caps: 'LINE_ITEM'},
  Coupon: {name: 'Coupon', perm: 'MANAGE_SYSTEM', sectionPerm: 'coupons', micon: 'miconCoupon', stateName: 'app.coupons.coupon', type: 'Coupon', searchType: 'co', apiParam: 'coupon', caps: 'COUPON'},
  Creative: {name: 'Creative', perm: 'MANAGE_ADVERTISING', micon: 'miconCreative', stateName: 'app.creatives.creative', type: 'Creative', searchType: 'cr', apiParam: 'creative', caps: 'CREATIVE'},
  LibraryCreative: {name: 'Library Creative', perm: 'MANAGE_ADVERTISING', micon: 'miconLibraryCreative', stateName: 'app.library-creatives.library-creative', type: 'LibraryCreative', searchType: 'lc', apiParam: 'libraryCreative', caps: 'LIBRARY_CREATIVE'},
  CdnUpload: {name: 'CDN Upload', perm: 'MANAGE_SYSTEM', micon: 'miconCdnUpload', stateName: 'app.cdn-uploads.cdn-upload', type: 'CdnUpload', searchType: 'cu', apiParam: 'cdnAsset', caps: 'CDN_UPLOAD'},
  CustomEvent: {name: 'Custom Event', perm: 'MANAGE_SYSTEM', micon: 'miconCustomEvent', stateName: 'app.custom-events.custom-event', type: 'CustomEvent', apiParam: 'customEvent', idParams: ['valueType'], caps: 'CUSTOM_EVENT', searchObject: 'CustomEventType'},
  DataExport: {name: 'Data Export', perm: 'MANAGE_SYSTEM', micon: 'miconDataExport', stateName: 'app.data-exports.data-export', type: 'DataExport', apiParam: 'dataExport', caps: 'DATA_EXPORT'},
  EarningsAccount: {name: 'Earnings Account', perm: ['MANAGE_BUSINESS', 'AX_PUBLISHER'], micon: 'miconEarningsAccount', childType: 'Site', stateName: 'app.earnings-accounts.earnings-account', stateNameAlts: ['app.publishers.publisher'], searchType: 'ea', apiParam: 'earningsAccount', type: 'EarningsAccount', caps: 'EARNINGS_ACCOUNT'},
  EmailTranslation: {name: 'Email Translations', perm: 'MANAGE_SYSTEM', micon: 'miconEmailTranslation', stateName: 'app.email-translations.email-translation', searchType: 'et', apiParam: 'emailTranslation', type: 'EmailTranslation', caps: 'EMAIL_TRANSLATION'},
  InvoiceTranslation: {name: 'Invoice Translations', perm: 'MANAGE_SYSTEM', micon: 'miconInvoiceTranslation', stateName: 'app.invoice-translations.invoice-translation', searchType: 'it', apiParam: 'invoiceTranslation', type: 'InvoiceTranslation', caps: 'INVOICE_TRANSLATION'},
  ExclusionLabel: {name: 'Exclusion Label', micon: 'miconExclusionLabel', stateName: 'app.search', type: 'ExclusionLabel', stateParams: {exclusionLabels: '@name'}, caps: 'EXCLUSION_LABEL', apiParam: 'exclusionLabel'},
  ExternalAdUnit: {name: 'External Ad Unit', micon: 'miconExternalAdUnit', stateName: 'app.external-ad-units.external-ad-unit', searchType: 'ex', type: 'ExternalAdUnit', apiParam: 'externalAdUnit', caps: 'EXTERNAL_AD_UNIT'},
  MatchingLabel: {name: 'Matching Label', micon: 'miconMatchingLabel', stateName: 'app.search', type: 'MatchingLabel', stateParams: {matchingLabels: '@name'}, caps: 'MATCHING_LABEL', apiParam: 'matchingLabel'},
  Forecast: {name: 'Forecast', micon: 'miconForecast', stateName: 'app.forecasts.forecast', searchType: 'fo', type: 'Forecast', apiParam: 'forecast', caps: 'FORECAST'},
  Label: {name: 'Label', micon: 'miconLabel', stateName: 'app.search', type: 'Label', apiParam: 'label', stateParams: {labels: '@name'}, caps: 'LABEL'},
  RateControlLabel: {name: 'Rate Control Label', micon: 'miconRateControlLabel', stateName: 'app.search', type: 'RateControlLabel', apiParam: 'rateControlLabel', stateParams: {rateControlLabels: '@name'}, caps: 'RATE_CONTROL_LABELS'},
  Layout: {name: 'Layout', micon: 'miconLayout', perm: 'MANAGE_LAYOUTS', stateName: 'app.layouts.layout', type: 'Layout', searchType: 'la', apiParam: 'layout', caps: 'LAYOUT'},
  LayoutInclude: {name: 'Layout Include', micon: 'miconLayoutInclude', perm: 'MANAGE_LAYOUTS', stateName: 'app.layout-includes.layout-include', type: 'LayoutInclude', searchType: 'li', apiParam: 'layoutInclude', caps: 'LAYOUT_INCLUDE'},
  LayoutExample: {name: 'Layout Example', micon: 'miconLayoutExample', perm: 'MANAGE_LAYOUTS', stateName: 'app.layout-examples.layout-example', type: 'LayoutExample', caps: 'LAYOUT_EXAMPLE'},
  Network: {name: 'Network', micon: 'miconNetwork', perm: 'MANAGE_SYSTEM', stateName: 'app.network', type: 'Network', apiParam: 'network', caps: 'NETWORK'},
  NotificationPreferences: {name: 'Notification Preferences', micon: 'miconNotificationPreferences', stateName: 'app.notification-preferences', type: 'NotificationPreferences', apiParam: 'notificationPreferences', caps: 'NOTIFICATION_PREFERENCES'},
  Order: {name: 'Order', micon: 'miconOrder', sectionPerm: 'orders', perm: 'MANAGE_ADVERTISING', childType: 'LineItem', stateName: 'app.orders.order', type: 'Order', searchType: 'or', apiParam: 'order', idParams: ['salespersonUser'], caps: 'ORDER', altCaps: 'LINE_ITEM_LIST'},
  Folio: {name: 'Folio', micon: 'miconFolio', sectionPerm: 'folios', perm: 'MANAGE_ADVERTISING', childType: 'CreativeSet', stateName: 'app.folios.folio', type: 'Folio', searchType: 'fo', apiParam: 'folio', caps: 'FOLIO'},
  MarketplaceProduct: {name: 'Marketplace Product', perm: 'MANAGE_SYSTEM', micon: 'miconMarketplaceProduct', searchType: 'mp', stateName: 'app.marketplace-products.marketplace-product', type: 'MarketplaceProduct', apiParam: 'axProduct', searchObject: 'AxProduct', caps: 'MARKETPLACE_PRODUCT'},
  Product: {name: 'Product', perm: 'MANAGE_SYSTEM', micon: 'miconProduct', searchType: 'pr', stateName: 'app.products.product', type: 'Product', apiParam: 'product', caps: 'PRODUCT'},
  ReportTemplate: {name: 'Report Template', perm: 'MANAGE_REPORT_TEMPLATES', micon: 'miconReportTemplate', searchType: 'rt', stateName: 'app.report-templates.report-template', type: 'ReportTemplate', apiParam: 'reportTemplate', caps: 'REPORT_TEMPLATE'},
  ReportSchedule: {name: 'Report Schedule', micon: 'miconReportSchedule', type: 'ReportSchedule', stateName: 'app.report-schedules.report-schedule', caps: 'REPORT_SCHEDULE'},
  ReportTranslation: {name: 'Report Translation', type: 'ReportTranslation', stateName: 'app.report-translations.report-translation', caps: 'REPORT_TRANSLATION'},
  ReportExecution: {name: 'Report', micon: 'miconReport', type: 'ReportExecution', target: '_blank', apiParam: 'reportExecution', caps: 'REPORT_EXECUTION'},
  Payment: {name: 'Payment', type: 'Payment', apiParam: 'payment', caps: 'PAYMENT'},
  ReferenceData: {name: 'Reference Data', perm: 'MANAGE_SYSTEM', micon: 'miconReferenceData', type: 'ReferenceData', apiParam: 'reportExecution', caps: 'REFERENCE_DATA'},
  Role: {name: 'Role', micon: 'miconRole', perm: 'MANAGE_SYSTEM', stateName: 'app.roles.role', type: 'Role', apiParam: 'role', caps: 'ROLE'},
  Site: {name: 'Site', micon: 'miconSite', perm: ['MANAGE_PUBLISHING', 'AX_PUBLISHER_ADMIN', 'AX_PUBLISHER'], childType: 'AdUnit', stateName: 'app.sites.site', searchType: 'si', type: 'Site', apiParam: 'site', caps: 'SITE'},
  SiteGroup: {name: 'Site Group', perm: 'MANAGE_SYSTEM', micon: 'miconSiteGroup', childType: 'Site', stateName: 'app.site-groups.site-group', searchType: 'sg', type: 'SiteGroup', apiParam: 'siteGroup', caps: 'SITE_GROUP'},
  Blocklist: {name: 'Blocklist', micon: 'miconBlocklist', perm: ['MANAGE_PUBLISHING', 'AX_PUBLISHER'], stateName: 'app.blocklists.blocklist', searchType: 'bl', type: 'Blocklist', apiParam: 'blocklist', caps: 'BLOCKLIST'},
  Ruleset: {name: 'Ruleset', micon: 'miconRuleset', perm: ['MANAGE_PUBLISHING', 'AX_PUBLISHER'], stateName: 'app.rulesets.ruleset', searchType: 'ru', type: 'Ruleset', apiParam: 'ruleset', caps: 'RULESET'},
  FirstPartyAudience: {name: 'First-Party Audience', micon: 'miconFirstPartyAudience', perm: ['MANAGE_PUBLISHING', 'AX_PUBLISHER'], stateName: 'app.first-party-audiences.first-party-audience', searchType: 'fp', type: 'FirstPartyAudience', apiParam: 'firstPartyAudience', caps: 'FIRST_PARTY_AUDIENCE'},
  Team: {name: 'Team', perm: 'MANAGE_SYSTEM', micon: 'miconTeam', stateName: 'app.teams.team', searchType: 'te', type: 'Team', apiParam: 'team', caps: 'TEAM'},
  TeamGroup: {name: 'Team Group', perm: 'MANAGE_SYSTEM', micon: 'miconTeamGroup', stateName: 'app.team-groups.team-group', searchType: 'tg', type: 'TeamGroup', apiParam: 'teamGroup', caps: 'TEAM_GROUP'},
  Trigger: {name: 'Trigger', micon: 'miconTrigger', sectionPerm: 'triggers', perm: 'MANAGE_SYSTEM', stateName: 'app.triggers.trigger', type: 'Trigger', searchType: 'tr', apiParam: 'trigger', caps: 'TRIGGER'},
  Segment: {name: 'Segment', micon: 'miconSegment', sectionPerm: 'segments', perm: 'MANAGE_SYSTEM', stateName: 'app.segments.segment', type: 'Segment', searchType: 'se', apiParam: 'segment', caps: 'SEGMENT'},
  FieldMapping: {name: 'Mapping', micon: 'miconFieldMapping', sectionPerm: 'fieldMapping', perm: 'MANAGE_SYSTEM', stateName: 'app.mappings.mapping', type: 'FieldMapping', searchType: 'ma', apiParam: 'fieldMapping', caps: 'FIELD_MAPPING'},
  Folder: {name: 'Folder', micon: 'miconFolder', sectionPerm: 'folder', perm: 'MANAGE_SYSTEM', stateName: 'app.folders.folder', type: 'Folder', searchType: 'fo', apiParam: 'folder', caps: 'FOLDER'},
  ExternalDemandSource: {name: 'External Demand Source', perm: 'MANAGE_SYSTEM', micon: 'miconExternalDemandSource', stateName: 'app.external-demand-sources.external-demand-source', searchType: 'es', type: 'ExternalDemandSource', apiParam: 'externalDemandSource', caps: 'EXTERNAL_DEMAND_SOURCE'},
  ContextServiceConfiguration: {name: 'Context Service Configuration', perm: 'MANAGE_SYSTEM', micon: 'miconContextServiceConfiguration', stateName: 'app.context-service-configurations.context-service-configuration', searchType: 'cs', type: 'ContextServiceConfiguration', apiParam: 'contextServiceConfiguration', caps: 'CONTEXT_SERVICE_CONFIGURATION'},
  Tier: {name: 'Tier', micon: 'miconTier', perm: 'MANAGE_SYSTEM', stateName: 'app.tiers.tier', type: 'Tier', apiParam: 'tier', caps: 'TIER'},
  User: {name: 'User', micon: 'miconUser', perm: 'MANAGE_SYSTEM', stateName: 'app.users.user', type: 'User', searchType: 'us', apiParam: 'user', caps: 'USER'},
  UserProfile: {name: 'User Profile', micon: 'miconUserProfile', stateName: 'app.user-profile', type: 'UserProfile', apiParam: 'userProfile', caps: 'USER_PROFILE'},
  UserSegment: {name: 'User Segment', micon: 'miconUserSegment', type: 'UserSegment', apiParam: 'userSegment', caps: 'USER_SEGMENT', queryStatUrlParam: 'app.line-items.line-item', queryStatParam: 'lineItem', childType: 'LineItem'},
  Workspace: {name: 'Dashboard', micon: 'miconWorkspace', stateName: 'app.workspaces.workspace', type: 'Workspace', apiParam: 'workspace', caps: 'WORKSPACE'}
};

export class ObjectTypeFinder {
  static findObjectType(object) {
    if (!object.url) {
      return {};
    }
    return _.find(OBJECT_TYPE, function(ot) {
      const urlCheck = ot.urlId || ot.name.replace(/ /g, '');
      return object.url.indexOf('/' + urlCheck.toLowerCase()) > 0;
    });
  }

  static findSearchObject(objectType: string) {
    const objType = OBJECT_TYPE[objectType];
    if (objType) {
      return objType;
    }
    return _.find(OBJECT_TYPE, function(ot) {
      return ot.searchObject === objectType;
    });
  }

  static findByCaps(caps: string) {
    return _.find(OBJECT_TYPE, function (ot) {
      return ot.caps === caps || ot.altCaps === caps;
    });
  }

  static findObjectFromStateName(stateName) {
    return _.find(OBJECT_TYPE, function(ot) {
      return (ot.stateName || '').indexOf(stateName) > -1 || (_.isArray(ot.stateNameAlts) ? _.find(ot.stateNameAlts, function(alt) {
        return alt.indexOf(stateName) > -1;
      }) : false);
    });
  }

  static findObjectFromUrl(url) {
    if (!_.isString(url)) {
      return null;
    }
    return _.find(OBJECT_TYPE, function(ot) {
      return (url.toLowerCase()).indexOf('v1/' + (ot.apiParam || 'nothing').toLowerCase()) > -1 || url === ot.stateName;
    });
  }
}
