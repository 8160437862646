import angular from 'angular';
import translate from 'angular-translate';
import uiBootstrap from 'angular-ui-bootstrap';
import uiRouter from '@uirouter/angularjs';
import toaster from 'angular-toastr';

import resources from '../../components/api/resources/resources';
import modalLoading from './util/modal-loading';


const MODULE_NAME = "standard-actions-module";

angular.module(MODULE_NAME, [
  translate,
  uiBootstrap,
  uiRouter,
  toaster,
  resources,
  modalLoading
]);

export default MODULE_NAME;