/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';

const MODULE_NAME = "diagnostic-request";

angular.module(MODULE_NAME, [])

  .factory('DiagnosticRequestExecution', function(adnExecutionResource) {
    let DiagnosticRequestExecution = adnExecutionResource('diagnosticrequests', {}, {}, 'diagnosticRequestExecution');

    DiagnosticRequestExecution.start = function(params) {
      return DiagnosticRequestExecution.$start(params);
    };

    return DiagnosticRequestExecution;
  });

export default MODULE_NAME;