/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import {Uuid} from "../../../../util/uuid";

import assetHelper from '../../../../util/asset-helper';

const MODULE_NAME = "cdn-asset";

angular.module(MODULE_NAME, [
  assetHelper
])

  .factory('CdnAsset', function(adnResource, adnAssetHelper) {
    const CdnAsset = adnResource('cdnassets', {}, {}, 'cdnAsset');
    CdnAsset.upload = function(assetId, data) {
      return adnAssetHelper.upload('cdnassets', null, data, assetId || Uuid.generate()).then(function(files) {
        return _.isArray(files) ? files[0] : files;
      });
    };
    return CdnAsset;
  });

export default MODULE_NAME;