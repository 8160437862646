/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import localNetworkProfile from '../../../session/local-network-profile';
import {ApiConfig} from "../../api";

const MODULE_NAME = "visitor-profile-fields";

angular.module(MODULE_NAME, [localNetworkProfile])

  .factory('VisitorProfileFields', function($http, LocalNetworkProfile) {
    let uri = ApiConfig.obtain().getVersionedUri('visitorprofilefields');
    return {
      get: function() {
        return $http.get(uri, {
          params: {context: LocalNetworkProfile.getNetworkId()}
        }).then(_.iteratee('data.results'));
      }
    };
  });

export default MODULE_NAME;