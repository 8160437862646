/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import uiRouter from '@uirouter/angularjs';

import template from '../default-title-template.html';
import nudePreviewTemplate from './nude-preview.html';

import resources from '../../../components/api/resources/resources';
import localNetworkProfile from '../../../components/session/local-network-profile';

import {ApiConfig} from "../../../components/api/api";
import {LocalCreativePreview} from "../../../components/session/local-creative-preview-data";

const MODULE_NAME = 'preview-full-screen-module';

angular.module(MODULE_NAME, [uiRouter, resources, localNetworkProfile])

  .config(function($stateProvider) {

    const providerParams = {
      url: '/preview-creative/?uuid&creativeId&networkId&creativeWidth&creativeHeight',
      views: {
        'content@nude': {
          template: nudePreviewTemplate,
          controllerAs: 'ctrl',
          controller: 'NudePreviewCtrl'
        },
        'title@nude': {
          template: template
        }
      },
      data: {
        public: true,
        title: "{{previewData.pageTitle}}"
      }
    };

    $stateProvider
      .state('nude.preview', providerParams);
  })
  .controller('NudePreviewCtrl', /*@ngInject*/ function($document, $scope, $stateParams, $window, $element, CreativePreview, $timeout) {
    const ctrl = this;

    const uuid = $stateParams.uuid;
    const spCreativeId = $stateParams.creativeId;
    const spNetworkId = $stateParams.networkId;
    const hasDirectInfo = spCreativeId && spNetworkId;

    if (!uuid && !hasDirectInfo) {
      ctrl.hasError = 'MISSING_UUID';
      return;
    }

    let previewData = {};
    const localCreativePreview = uuid ? new LocalCreativePreview().get(uuid) : null;
    previewData.networkId = _.get(localCreativePreview, ['networkId']) || spNetworkId;

    previewData.creativeWidth = $stateParams.creativeWidth;
    previewData.creativeHeight = $stateParams.creativeHeight;
    previewData.creativeId = spCreativeId;
    if (localCreativePreview) {
      previewData = _.assign(previewData, _.get(localCreativePreview, ['previewData']) || {}, {networkId: previewData.networkId});
    }

    if (!previewData.networkId || !previewData.creativeId) {
      ctrl.hasError = 'MISSING_INFO';
      return;
    }
    doPreview($window, $document, $element, $timeout, $scope, previewData, true, CreativePreview, ctrl);
  });

export function doPreview($window, $document, $element, $timeout, $scope, previewData, isPublic, CreativePreview, ctrl, targetId) {
  function checkForImpCallback() {
    if (_.isFunction(previewData.impressionCallback)) {
      previewData.impressionCallback();
    }
  }

  const scriptIsOn = _.isUndefined(previewData.allowScript) ? true : previewData.allowScript;
  const safeTargetId = targetId || 'targetId';
  const creativeData = {
    creativeId: previewData.creativeId,
    targetId: safeTargetId,
    creativeData: previewData,
    creativeWidth: previewData.creativeWidth,
    creativeHeight: previewData.creativeHeight,
    isolateFrame: !scriptIsOn,
    blockScript: !scriptIsOn,
    clearTarget: previewData.clearTarget,
    onImpressionResponse: function() {
      checkForImpCallback();
    }
  };

  $timeout(function() {
    $window.adn = $window.adn || {};
    $window.adn.calls = $window.adn.calls || [];
    const env = ApiConfig.obtain().getId().replace("_adn", "");
    $window.adn.calls.push(function() {
      $window.adn.request({
        env: env,
        networkId: previewData.networkId,
        creatives: [creativeData],
        onError: function() {
          if (isPublic) {
            ctrl.publicError = "We did not succeed in providing a preview.";
            return;
          }

          CreativePreview.get({id: previewData.creativeId}, function(data) {
            creativeData.creativeContent = data.html;

            const directRequest = {};
            _.assign(directRequest, _.pick(creativeData, ['targetId', 'isolateFrame', 'blockScript', 'clearTarget', 'onImpressionResponse', 'creativeWidth', 'creativeHeight']));
            directRequest.creativeContent = data.html;
            directRequest.env = env;
            directRequest.networkId = previewData.networkId;

            $window.adn.request(directRequest);
          }, function(errData) {
            ctrl.errorMessage = errData;
            $scope.$apply();
            checkForImpCallback();
          });
        }
      });
    });
  }, 200);
}


export default MODULE_NAME;