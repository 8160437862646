/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';

import {HttpHelper} from '../../../util/http-helper';

const MODULE_NAME = "workspace";

angular.module(MODULE_NAME, [])
  .factory("Workspace", function(adnResource, $http) {
    return adnResource('workspaces', {}, HttpHelper.obtain($http).transformJsonActions('data'));
  });

export default MODULE_NAME;