type AuthEvent = Record<string, string>;

export const AUTH_EVENT: AuthEvent = {
  loginSuccess: 'adn.event:auth-login-success',
  loginFailed: 'adn.event:auth-login-failed',
  login2faRequired: 'adn.event:auth-login-2fa-required',
  logoutSuccess: 'adn.event:auth-logout-success',
  sessionTimeout: 'adn.event:auth-session-timeout',
  notAuthenticated: 'adn.event:auth-not-authenticated',
  notAuthorized: 'adn.event:auth-not-authorized',
  networkChange: 'adn.event:auth-network-change'
};

type PermissionInfo = {
  id: string;
  apiKey: string;
  scope: string;
  application: string;
  roleType?: string;
  checkAdvancedLogging?: boolean;
  networks?: Array<string>;
  app: string
};

type Permission = Record<string, PermissionInfo>;

export const PERMISSIONS: Permission = {
  manageAdvertising: {
    id: 'manageAdvertising',
    apiKey: 'MANAGE_ADVERTISING',
    scope: 'TEAM',
    application: 'ADVERTISING',
    roleType: 'INTERNAL',
    app: 'ADVERTISING'
  },
  manageBusiness: {
    id: 'manageBusiness',
    apiKey: 'MANAGE_BUSINESS',
    scope: 'NETWORK',
    application: 'ADVERTISING',
    app: 'ADVERTISING'
  },
  manageLayouts: {
    id: 'manageLayouts',
    apiKey: 'MANAGE_LAYOUTS',
    scope: 'NETWORK',
    application: 'ADVERTISING',
    app: 'ADVERTISING'
  },
  managePublishing: {
    id: 'managePublishing',
    apiKey: 'MANAGE_PUBLISHING',
    scope: 'TEAM',
    roleType: 'INTERNAL',
    application: 'ADVERTISING',
    app: 'ADVERTISING'
  },
  axPublisher: {
    id: 'axPublisher',
    apiKey: 'AX_PUBLISHER',
    scope: 'TEAM',
    roleType: 'INTERNAL',
    application: 'ADVERTISING',
    app: 'MARKETPLACE'
  },
  axAdvertiser: {
    id: 'axAdvertiser',
    apiKey: 'AX_ADVERTISER',
    scope: 'TEAM',
    roleType: 'INTERNAL',
    application: 'ADVERTISING',
    app: 'MARKETPLACE'
  },
  creativeAgency: {
    id: 'creativeAgency',
    apiKey: 'CREATIVE_AGENCY',
    scope: 'TEAM',
    roleType: 'INTERNAL',
    application: 'ADVERTISING',
    app: 'MARKETPLACE'
  },
  axPublisherAdmin: {
    id: 'axPublisher',
    apiKey: 'AX_PUBLISHER_ADMIN',
    scope: 'NETWORK',
    roleType: 'INTERNAL',
    application: 'ADVERTISING',
    app: 'MARKETPLACE'
  },
  axAdvertiserAdmin: {
    id: 'axAdvertiserAdmin',
    apiKey: 'AX_ADVERTISER_ADMIN',
    scope: 'NETWORK',
    roleType: 'INTERNAL',
    application: 'ADVERTISING',
    app: 'MARKETPLACE'
  },
  manageReportTemplates: {
    id: 'manageReportTemplates',
    apiKey: 'MANAGE_REPORT_TEMPLATES',
    scope: 'NETWORK',
    application: 'ADVERTISING',
    app: 'ADVERTISING'
  },
  manageNetworkDashboards: {
    id: 'manageNetworkDashboards',
    apiKey: 'MANAGE_NETWORK_DASHBOARDS',
    scope: 'NETWORK',
    application: 'ADVERTISING',
    app: 'ADVERTISING'
  },
  selfServiceUserAdmin: {
    id: 'selfServiceUserAdmin',
    apiKey: 'SELF_SERVICE_USER_ADMIN',
    scope: 'NETWORK',
    roleType: 'EXTERNAL',
    application: 'ADVERTISING',
    app: 'SELF_SERVICE'
  },
  manageSystem: {
    id: 'manageSystem',
    apiKey: 'MANAGE_SYSTEM',
    scope: 'NETWORK',
    roleType: 'INTERNAL',
    application: 'ADVERTISING',
    app: 'ADVERTISING'
  },
  downloadLogs: {
    id: 'downloadLogs',
    apiKey: 'DOWNLOAD_LOGS',
    scope: 'NETWORK',
    checkAdvancedLogging: true,
    roleType: 'INTERNAL',
    application: 'ADVERTISING',
    app: 'ADVERTISING'
  },
  runReports: {
    id: 'runReports',
    apiKey: 'RUN_REPORTS',
    scope: 'TEAM',
    application: 'ADVERTISING',
    app: 'ADVERTISING'
  },
  selfServiceOwnAdvertising: {
    id: 'selfServiceOwnAdvertising',
    apiKey: 'SELF_SERVICE_OWN_ADVERTISING',
    scope: 'TEAM',
    roleType: 'EXTERNAL',
    application: 'ADVERTISING',
    app: 'SELF_SERVICE'
  },
  viewAllObjects: {
    id: 'viewAllObjects',
    apiKey: 'VIEW_ALL_OBJECTS',
    scope: 'NETWORK',
    roleType: 'INTERNAL',
    application: 'ADVERTISING',
    app: 'ADVERTISING'
  },
  dataAdmin: {
    id: 'dataAdmin',
    apiKey: 'DATA_ADMIN',
    scope: 'NETWORK',
    roleType: 'INTERNAL',
    application: 'DATA',
    app: 'DATA'
  },
  manageDataSegments: {
    id: 'manageData',
    apiKey: 'MANAGE_DATA',
    scope: 'TEAM',
    roleType: 'INTERNAL',
    application: 'DATA',
    app: 'DATA'
  }
};
