/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import translate from 'angular-translate';
import highchartsNg from 'highcharts-ng';
import moment from 'moment';

import {ADN_CHART_COUNT_TYPES} from '../options/chart-options';
import chartOptionsDirective from '../directives/chart-options/chart-options-directive';
import promiseSpinnerComponent from '../directives/html/promise-spinner-component';

import template from './line.html';
import {DEVICE_ORDER_ID} from "./perf-total-component";

const MODULE_NAME = "line-chart-directive";

angular.module(MODULE_NAME, [
  highchartsNg,
  translate,
  chartOptionsDirective,
  promiseSpinnerComponent
])

  .directive('adnLineChart', function($translate) {
    return {
      restrict: 'A',
      scope: {
        stats: '<adnLineChart',
        statsKey: '@',
        chartType: '@',
        title: '@',
        keyed: '@?',
        orderBy: '@?',
        eventHook: '<?',
        countType: '<?'
      },
      template: template,
      link: {
        // using preLink to fill out chart config BEFORE the highchart directive is compiled
        pre: function preLink(scope) {
          scope.haveStats = false;
          let theSeries = _.map(scope.stats.totals[scope.statsKey], function(d, k) {
            return {
              name: scope.keyed ? ($translate.instant(scope.keyed + "." + k) || k) : k,
              id: k
            };
          });

          let createChartConfig = function(height) {
            return {
              chart: {
                type: 'line',
                zoomType: 'x',
                showAxes: true,
                height: height
              },
              plotOptions: {
                series: {
                  marker: {
                    enabled: false
                  },
                  turboThreshold: 2000
                }
              },
              tooltip: {
                shared: true
              },
              yAxis: {
                title: {text: null},
                min: 0,
                allowDecimals: false,
                id: 0
              },
              xAxis: {
                type: 'datetime',
                title: {
                  enabled: false,
                  text: 'Date and Time'
                }
              },
              legend: {
                layout: "vertical",
                verticalAlign: "top",
                width: 110,
                align: "right"
              },
              credits: {
                enabled: false
              },
              accessibility: {
                enabled: false
              },
              title: {
                text: null
              },
              series: theSeries
            };
          };

          scope.chartHeight = 175;
          scope.statsChart = createChartConfig(scope.chartHeight);

          let startStatsGet = function() {
            scope.totals = scope.stats.totals;

            if (scope.orderBy === 'VALUE') {
              scope.statsChart.series = _.slice(_.orderBy(_.map(scope.totals[scope.statsKey], function(d, k) {
                return {
                  name: scope.keyed ? ($translate.instant(scope.keyed + "." + k) || k) : k,
                  id: k,
                  orderBy: d
                };
              }), ['orderBy'], 'desc'), 0, 40);
            } else if (scope.orderBy === 'MOBILE_INFO') {
              _.forEach(scope.statsChart.series, (d) => {
                const id = _.get(d, 'id');
                d.orderId = DEVICE_ORDER_ID[id] || 1000;
              });
              scope.statsChart.series = _.orderBy(scope.statsChart.series, 'orderId');
            }

            _.forEach(scope.statsChart.series, function(series) {
              let runningTotal = 0;

              let keyedChunks = _.keyBy(scope.stats.chunks, 'chunkStart');
              series.data = _.map(scope.stats.chunkRange, function(statsDate) {
                let dataPoint = null;
                if (keyedChunks[statsDate]) {
                  dataPoint = _.get(keyedChunks, [statsDate, scope.statsKey, series.id]);
                }
                let value = dataPoint || 0;
                runningTotal += value;
                return {
                  x: moment(statsDate).valueOf(),
                  y: scope.countType === ADN_CHART_COUNT_TYPES.cumulative ? runningTotal : value
                };
              });
            });
            scope.haveStats = true;
          };
          startStatsGet();
          _.set(scope.eventHook, 'redraw', function() {
            startStatsGet();
          });
        },
        post: function postLink(scope, element) {
          element.addClass('chart');
        }
      }
    };
  });

export default MODULE_NAME;