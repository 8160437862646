/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';

const MODULE_NAME = 'https-prefixer-directive';

angular.module(MODULE_NAME, [])

  .directive('adnHttpsPrefixer', function() {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function(scope, element, attrs, controller) {
        // Taken from https://stackoverflow.com/questions/19482000/angularjs-add-http-prefix-to-url-input-field/
        function ensureHttpPrefix(value) {
          // Need to add prefix if we don't have http:// prefix already AND we don't have part of it
          if (value && (!/^(https:\/\/)/i.test(value)) && 'https://'.indexOf(value) === -1) {
            controller.$setViewValue('https://' + value);
            controller.$render();
            return 'https://' + value;
          } else {
            return value;
          }
        }

        controller.$formatters.push(ensureHttpPrefix);
        controller.$parsers.splice(0, 0, ensureHttpPrefix);
      }
    };
  });

export default MODULE_NAME;