/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import assetHelper from '../../../util/asset-helper';
import {ApiConfig} from "../../api";
import {bidUpdateDebug} from "./bid-data-export";

const MODULE_NAME = "bids";

angular.module(MODULE_NAME, [
  assetHelper
])

  .factory('Bids', function($http, $q) {
    return {
      bidUpdates: function(lineItemId) {
        return $http.get(ApiConfig.obtain().getVersionedUri(["bidupdates", "lineitem", lineItemId]), {
          params: _.assign({}, {})
        }).then(_.iteratee('data'));
      },
      bidUpdatesDebug: function() {
        const data = bidUpdateDebug;
        return $q.resolve().then(function() {return data;});
      }
    };
  });

export default MODULE_NAME;
