/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import {OBJECT_TYPE} from "../constants/object-type";

import template from './top-articles.html';
import moment from 'moment';

import resources from '../../../components/api/resources/resources';
import userPermissions from '../../../components/session/local-user-permissions';
import columnSelector from './column-selector-component';
import _ from "lodash";

const MODULE_NAME = 'top-articles-table';

angular.module(MODULE_NAME, [resources, userPermissions, columnSelector])

  .component('adnTopArticlesTable', {
    template: template,
    controllerAs: 'ctrl',
    bindings: {
      object: '<',
      parentType: '@',
      dateSelectorId: '@'
    },
    controller: function($scope, $rootScope, $log, statsResource, ChartOptionsUtil) {
      const ctrl = this;
      ctrl.sel = {};

      ctrl.sorting = {
        orderByParam: 'impressions',
        asc: true
      };

      ctrl.$onInit = function() {
        let chartOptionObject = OBJECT_TYPE[ctrl.parentType];
        let params = {
          id: ctrl.object.id,
          idKey: chartOptionObject.apiParam + 'Id',
        };

        function checkTargeting() {
          ctrl.targetingUrls = {};
          const safeUrls = _.get(ctrl.object, ['targeting', 'articleTarget', 'urls'], []);
          _.forEach(safeUrls, function(url) {
            ctrl.targetingUrls[url] = ctrl.object.targeting.articleTarget.negated ? 'EXCLUDED' : 'INCLUDED';
          });
          ctrl.targetingUrlsOpen = false;
          if (safeUrls.length > 0) {
            ctrl.targetingUrlsOpen = ctrl.object.targeting.articleTarget.negated ? 'EXCLUDED' : 'INCLUDED';
          }
        }
        checkTargeting();

        function saveTargeting() {
          ctrl.isSaving = true;
          ctrl.object.savePartial(['targeting']).then(function(savedLineItem) {
            checkTargeting();
            ctrl.isSaving = false;
            $rootScope.$emit('lineItemTargetingUpdate', savedLineItem);
          });
        }

        ctrl.clearAllTargeting = function() {
          ctrl.object.targeting.articleTarget.negated = false;
          ctrl.object.targeting.articleTarget.urls = [];
          saveTargeting();
        };

        ctrl.resetTargeting = function() {
          checkTargeting();
        };

        ctrl.targetArticle = function(url) {
          if (ctrl.object.targeting.articleTarget.urls.length === 0) {
            ctrl.object.targeting.articleTarget.negated = false;
          }
          ctrl.object.targeting.articleTarget.urls.push(url);
          saveTargeting();
        };

        ctrl.excludeArticle = function(url) {
          if (ctrl.object.targeting.articleTarget.urls.length === 0) {
            ctrl.object.targeting.articleTarget.negated = true;
          }
          ctrl.object.targeting.articleTarget.urls.push(url);
          saveTargeting();
        };

        ctrl.removeFromTargeting = function(url) {
          ctrl.object.targeting.articleTarget.urls = _.filter(ctrl.object.targeting.articleTarget.urls, function(targetUrl) {
            return targetUrl !== url;
          });
          saveTargeting();
        };

        ctrl.sortUpdate = function(field) {
          if (field.key === ctrl.sorting.orderByParam) {
            ctrl.sorting.asc = !ctrl.sorting.asc;
            return;
          }
          ctrl.sorting.orderByParam = field.key;
          ctrl.sorting.asc = true;
        };

        let chartOptions = ChartOptionsUtil.getOptions(chartOptionObject.caps);
        ctrl.parentParam = chartOptionObject.apiParam;

        ctrl.startDate = 'UNDEFINED';
        const articleListener = $rootScope.$on('chartDates' + chartOptions.idKey + (ctrl.dateSelectorId + ''), function(event, data) {
          if (ctrl.startDate !== 'UNDEFINED' && moment(ctrl.startDate).isSame(data.startDate) && moment(ctrl.endDate).isSame(data.endDate)) {
            return;
          }

          ctrl.startDate = data.startDate;
          ctrl.endDate = data.endDate;

          ctrl.loaded = false;
          ctrl.articles = null;
          ctrl.error = false;

          if (ctrl.startDate && ctrl.endDate) {
            params.startDate = ctrl.startDate;
            params.endDate = ctrl.endDate;
          }

          params.aggregateTo = 'article';
          params.groupBy = null;
          params.topN = 100;

          statsResource.getArticles(params, function (data) {
            ctrl.articles = data.chunks;
            _.remove(ctrl.articles, function(row) {
              return row.article === "ALL_OTHERS";
            });
            ctrl.articles.forEach(function(article) {
              if (article.matchScore) {
                if (article.matchScore === 'SLIGHTLY_RELATED') {
                  article.matchScore = 1;
                }
                if (article.matchScore === 'SOMEWHAT_RELATED') {
                  article.matchScore = 2;
                }
                if (article.matchScore === 'MODERATELY_RELATED') {
                  article.matchScore = 3;
                }
                if (article.matchScore === 'CLOSELY_RELATED') {
                  article.matchScore = 4;
                }
                if (article.matchScore === 'VERY_CLOSELY_RELATED') {
                  article.matchScore = 5;
                }
              }
            });
            ctrl.loaded = true;
          }, function (err) {
            $log.warn(err);
            ctrl.error = true;
          }, $scope);
        });

        $scope.$on('$destroy', function() {
          articleListener();
        });
      };
    }
  });

export default MODULE_NAME;