/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import uiRouter from '@uirouter/angularjs';

import {AUTH_EVENT} from "../../../components/auth/auth-constants";

import auth from '../../../components/auth/auth';
import {LocalDebugInfo} from '../../../components/session/local-debug-info';
import resources from '../../../components/api/resources/resources';
import workspaceRegistry from '../../../components/workspace-registry';
import networkHelper from '../../../components/util/network-helper';

const MODULE_NAME = "session-info-directive";

angular.module(MODULE_NAME, [
  uiRouter,
  resources,
  auth,
  workspaceRegistry,
  networkHelper,
])
  .directive('adnSessionInfo', function($rootScope, $state, localUserProfile, AuthService, WorkspaceRegistry) {
    return {
      restrict: 'A',
      scope: true,
      link: function(scope) {
        let user = localUserProfile.get();
        if (!user) {
          return;
        }
        scope.user = user;

        scope.signOut = function() {
          AuthService.signOut();
          LocalDebugInfo.obtain().setInfo('expired', new Date());
          $state.go('sign-in', {explicitlySignedOut: true});
          $rootScope.$broadcast(AUTH_EVENT.logoutSuccess);
        };

        WorkspaceRegistry.getPromise();

        scope.profile = user.profile;
      }
    };
  });

export default MODULE_NAME;