/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';

const MODULE_NAME = "team-group";

angular.module(MODULE_NAME, [])

  .factory("TeamGroup", function(adnResource) {
    const TeamGroup = adnResource('teamgroups', {teamId: '@teamId'}, {}, 'teamGroup');

    TeamGroup.updateTeamToTeamGroup = function(teamGroupId, teamId, removeOnly) {
      TeamGroup.updateTeamGroup({id: teamGroupId, teamId: teamId, removeOnly: !!removeOnly});
    };

    return TeamGroup;
  });

export default MODULE_NAME;