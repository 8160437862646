/**
 * Copyright © 2024 Adnuntius AS.
 */
export class LocalStorage {
  private static instance: LocalStorage;

  prefix = "";

  private constructor() {
  }

  public static obtain(): LocalStorage {
    return this.instance || (this.instance = new LocalStorage());
  }

  setPrefix(prefix: string) {
    this.prefix = prefix.length > 0 ? prefix + "." : "";
  }

  setItem(key: string, data: any) {
    this.setItemWithStorage(localStorage, key, data);
  }

  setSessionItem(key: string, data: any) {
    this.setItemWithStorage(sessionStorage, key, data);
  }

  private setItemWithStorage(storageMethod, key: string, data: any) {
    storageMethod.setItem(this.prefix + key, JSON.stringify(data));
  }

  getItem(key: string) {
    return this.getItemWithStorage(localStorage, key);
  }

  getSessionItem(key: string) {
    return this.getItemWithStorage(sessionStorage, key);
  }

  private getItemWithStorage(storageMethod, key: string) {
    try {
      return JSON.parse(storageMethod.getItem(this.prefix + key));
    } catch (e) {
      return null;
    }
  }

  remove(key: string) {
    localStorage.removeItem(this.prefix + key);
    sessionStorage.removeItem(this.prefix + key);
  }
}