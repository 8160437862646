/*
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import {Uuid} from "../../../util/uuid";

import {HttpHelper} from '../../../util/http-helper';
import order, {updateEndDateForManualInput} from './order';
import {ResourceHelper} from "../../../util/resource-helper";

const MODULE_NAME = "line-item";

const transforms = function($http) {
  const responseTransformer = function(lineItem) {
    if (!lineItem) {
      return lineItem;
    }
    lineItem.startDate = lineItem.startDate && new Date(lineItem.startDate);
    lineItem.endDate = lineItem.endDate && new Date(lineItem.endDate);
    if (_.isPlainObject(lineItem.bidSpecification)) {
      lineItem.bidSpecification.cpm = lineItem.bidSpecification.cpm || {};
      lineItem.bidSpecification.cpc = lineItem.bidSpecification.cpc || {};
      lineItem.bidSpecification.cpa = lineItem.bidSpecification.cpa || {};
    }
    return lineItem;
  };

  const saveAndApproveTransforms = function($http) {
    return {
      params: {ignoreProduct: '@ignoreProduct'},
      transformRequest: HttpHelper.obtain($http).prependRequestTransform(function(lineItem) {
        if (_.isPlainObject(lineItem.bidSpecification)) {
          let keys = ['cpm', 'cpc'];
          _.forEach(keys, function(k) {
            ResourceHelper.checkMoney(lineItem, ['bidSpecification', k]);
          });
          ResourceHelper.checkMoney(lineItem, ['bidSpecification', 'cpa'], 'cost');
          ResourceHelper.checkMoney(lineItem, ['bidSpecification', 'cpa'], 'initialECpm');
        }
        ResourceHelper.checkObjectives(lineItem);
        updateEndDateForManualInput(lineItem, 'endDate');
        delete lineItem.reportExecutions;
        return lineItem;
      }),
      transformResponse: HttpHelper.obtain($http).appendResponseTransform(responseTransformer)
    };
  };

  return {
    get: {
      transformResponse: HttpHelper.obtain($http).appendResponseTransform(responseTransformer)
    },
    query: {
      transformResponse: HttpHelper.obtain($http).appendResponseTransform(function(page, headers, status) {
        if (HttpHelper.obtain($http).isSuccess(status)) {
          page.results = _.map(page.results, responseTransformer);
        }
        return page;
      })
    },
    approve: saveAndApproveTransforms($http),
    save: saveAndApproveTransforms($http),
    dimensionsCheck: {
      params: {limitToRequestedInLastDay: '@limitToRequestedInLastDay'}
    },
    lineItem: {
      params: {lineItemId: '@lineItemId', orderId: '@orderId', name: '@name', copyTargeting: '@copyTargeting', userState: '@userState', startDate: '@startDate', endDate: '@endDate'}
    },
    copy: {
      params: {lineItemId: '@lineItemId', orderId: '@orderId', name: '@name', copyTargeting: '@copyTargeting', copyCreatives: '@copyCreatives', userState: '@userState'}
    }
  };
};

angular.module(MODULE_NAME, [order])

  .factory("LineItemTemplate", function(adnResource, $http, $q) {
    const LineItemTemplate = adnResource('lineitemtemplates', {}, transforms($http), "lineItemTemplate");
    LineItemTemplate.create = function(defaults) {
      return new LineItemTemplate(_.merge({
        targeting: {},
        type: 'AUCTION',
        companionAds: 'NONE',
        deliveryCurve: 'EVEN',
        bidSpecification: {
          cpm: {},
          cpc: {}
        }
      }, defaults));
    };

    LineItemTemplate.copyAsLineItem = function(lineItemTemplate, newId, copyTargeting) {
      if (!lineItemTemplate || !lineItemTemplate.id) {
        return $q.reject("Insufficient parameters for the line item template copy");
      }
      return LineItemTemplate.lineItem({id: lineItemTemplate.id, startDate: lineItemTemplate.startDate, endDate: lineItemTemplate.endDate, orderId: _.get(lineItemTemplate, ['order', 'id'], ''), lineItemId: newId || '', name: lineItemTemplate.name, copyTargeting: _.isBoolean(copyTargeting) ? copyTargeting : true, userState: lineItemTemplate.userState || ''});
    };

    LineItemTemplate.copyLineItemTemplate = function(lineItemTemplate, newId, copyTargeting) {
      if (!lineItemTemplate || !lineItemTemplate.id) {
        return $q.reject("Insufficient parameters for the line item template copy");
      }
      return LineItemTemplate.copy({id: lineItemTemplate.id, orderId: _.get(lineItemTemplate, ['order', 'id'], ''), lineItemId: newId || '', name: lineItemTemplate.name, copyTargeting: _.isBoolean(copyTargeting) ? copyTargeting : true, userState: lineItemTemplate.userState || ''});
    };

    return LineItemTemplate;
  })

  .factory("LineItemReview", function(adnResource) {
    return adnResource('lineitems/review', {}, {}, "lineItemReview");
  })

  .factory("LineItemBookings", function(adnResource) {
    return adnResource('lineitems/bookings', {}, {}, "lineItemBookings");
  })

  .factory("LineItem", function($q, $http, adnResource, Order) {

    const LineItem = adnResource('lineitems', {}, transforms($http), "lineItem");

    LineItem.getHydrated = function(params) {
      return LineItem.get(params).$promise.then(function(lineItem) {
        let relations = [];

        if (lineItem.order) {
          relations.push(Order.getDataView({id: lineItem.order.id}).then(function(order) {
            lineItem.order = order;
          }));
        }

        return $q.all(relations).then(_.constant(lineItem));
      });
    };

    LineItem.create = function(defaults) {
      return new LineItem(_.merge({
        targeting: {},
        type: 'AUCTION',
        companionAds: 'NONE',
        deliveryCurve: 'EVEN',
        bidSpecification: {
          cpm: {},
          cpc: {}
        }
      }, defaults));
    };

    LineItem.dimCheck = function(id) {
      return LineItem.dimensionsCheck({id: id, limitToRequestedInLastDay: true}).$promise;
    };

    LineItem.copyLineItem = function(lineItem, newId, copyTargeting, copyCreatives) {
      if (!lineItem || !lineItem.id) {
        return $q.reject("Insufficient parameters for the line item copy");
      }
      return LineItem.copy({id: lineItem.id, orderId: _.get(lineItem, ['order', 'id'], ''), lineItemId: newId || '', name: lineItem.name, copyTargeting: _.isBoolean(copyTargeting) ? copyTargeting : true, copyCreatives: _.isBoolean(copyCreatives) ? copyCreatives : true, userState: lineItem.userState || ''});
    };

    LineItem.prototype.clone = function(paramOverrides) {
      paramOverrides = paramOverrides || {};
      if (!_.has(paramOverrides, 'id')) {
        paramOverrides.id = Uuid.generate();
      }

      let newLineItem = new LineItem(_.assign({}, this, paramOverrides, {
        validationWarnings: [],
        executionState: null,
        notes: [],
        reportExecutions: []
      }));
      if (!newLineItem.bidSpecification || !newLineItem.bidSpecification.cpa || !newLineItem.bidSpecification.cpa.cost || !newLineItem.bidSpecification.cpa.cost.amount || !newLineItem.bidSpecification.cpa.initialECpm || !newLineItem.bidSpecification.cpa.initialECpm.amount || !newLineItem.bidSpecification.cpa.customEventType || !newLineItem.bidSpecification.cpa.lookBackHours) {
        newLineItem.bidSpecification = newLineItem.bidSpecification || {};
        newLineItem.bidSpecification.cpa = {};
      }
      return newLineItem;
    };

    return LineItem;
  });

export default MODULE_NAME;