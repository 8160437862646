import angular from 'angular';
import * as _ from 'lodash';

import template from './collapsible-sidebar.html';
import {permissionsSetUp} from "../../../components/session/local-user-permissions";
import {LocalNetworkInfo} from "../../../components/session/local-network-info";

const MODULE_NAME = 'collapsible-sidebar';

angular.module(MODULE_NAME, [])
  .component('adnCollapsibleSidebar', {
    bindings: {
      base: '<'
    },
    controller: function(LocalUserPermissions, LocalNetworkProfile, $location, $scope, $state, localUserProfile) {
      const ctrl = this;
      ctrl.$onInit = function() {
        permissionsSetUp(LocalUserPermissions, LocalNetworkProfile, localUserProfile, $scope, $location, $state, ctrl);

        ctrl.showFolios = LocalNetworkInfo.obtain().getInfo("creativeSetCount") + LocalNetworkInfo.obtain().getInfo("folioCount") > 0;
        ctrl.showMarketplaceReviews = LocalNetworkInfo.obtain().getInfo("axProductsWithReviewCount");
        ctrl.showReleaseNotesAdmin = ['adnuntius@adnuntius.com', 'broker1@adnuntius.com'].indexOf(localUserProfile.get('username')) > -1;

        ctrl.sectionClicked = function(menuViewParam) {
          ctrl.menuView[menuViewParam] = !ctrl.menuView[menuViewParam];

          if (!ctrl.menuView[menuViewParam]) {
            return;
          }

          _.forEach(ctrl.menuView, function(val, key) {
            if (menuViewParam === key || ctrl.menuViewFromPage[key]) {
              return;
            }
            ctrl.menuView[key] = false;
          });
        };
      };
    },
    controllerAs: 'ctrl',
    template: template
  });

export default MODULE_NAME;