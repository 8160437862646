/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';

import resources from '../../../components/api/resources/resources';
import columnSelector from '../controller/column-selector-component';
import _ from "lodash";
import {DEVICE_ORDER_ID} from "./perf-total-component";
import {getAvailableFields} from "./chart-options/chart-options-directive";

const MODULE_NAME = 'performance-totals-table';

angular.module(MODULE_NAME, [resources, columnSelector])

  .component('adnPerformanceTotals', {
    controllerAs: 'ctrl',
    bindings: {
      chartType: '@',
      keyed: '@',
      orderBy: '@',
      param: '@',
      useAlt: '<',
      changeHook: '<'
    },
    template: `
      <ul class="list-unstyled doubleColumned totalsTable">
        <li ng-repeat="kv in ctrl.items">
          <span ng-if="kv" class="titleField">
              <span ng-if="kv.keyBase"><span class="fa fa-fw {{kv.baseIcon}}" ng-if="kv.icon"></span><span class="fa fa-fw {{kv.icon}}" ng-if="kv.icon"></span> {{kv.key}}</span>
              <span ng-if="!kv.keyBase && ctrl.totals[kv.key] !== undefined"><span class="fa fa-fw {{kv.icon}}" ng-if="kv.icon"></span>
              <span ng-if="!kv.keyBase && !ctrl.totals[kv.key].count">{{ctrl.keyed ? (ctrl.keyed + '.' + (kv.tKey || kv.key) | adnFieldTranslate) : kv.name}}</span><span ng-if="ctrl.totals[kv.key].count">{{(kv.tKey || kv.key)}}<span ng-if="ctrl.totals[kv.key].averageValue"> count | avg</span></span></span></span>
          <span  ng-if="kv" class="numericColumn">
            <span ng-if="kv.keyBase">{{ctrl.totals[kv.keyBase][kv.key] | adnStat }}</span>
            <span ng-if="!kv.keyBase && ctrl.totals[kv.key].count === undefined">{{ctrl.totals[ctrl.useAlt ? (kv.altId || kv.key) : kv.key] | adnStat : kv.key }}</span>
            <span ng-if="!kv.keyBase && ctrl.totals[kv.key].count !== undefined">{{ctrl.totals[ctrl.useAlt ? (kv.altId || kv.key) : kv.key].count | adnStat : kv.key }}<span ng-if="ctrl.totals[kv.key].averageValue"> | {{ctrl.totals[kv.key].averageValue | adnStat : kv.key }}</span></span>
          </span>
        </li>
      </ul>
    `,
    controller: function(ChartOptionsUtil, localUserProfile) {
      const ctrl = this;
      ctrl.sel = {};

      function valIsEqual(val1, val2) {
        const isMoney = _.get(val1, ['currency']);
        if (isMoney) {
          return _.get(val1, ['amount']) === _.get(val2, ['amount']) && _.get(val1, ['currency']) && _.get(val2, ['currency']);
        }
        return val1 === val2;
      }

      function rowsOrganiser() {
        let fields;
        if (ctrl.chartType) {
          const chartOptions = ChartOptionsUtil.getOptions(ctrl.chartType).totalsFields;
          const selectableFields = _.keyBy(getAvailableFields(ChartOptionsUtil, localUserProfile, ctrl.chartType), 'id');
          const chartOptionsFields = _.filter(chartOptions, function(co) {
            return selectableFields[co.id];
          });
          fields = _.filter(chartOptionsFields, function(f) {
            return f.id !== 'customFields';
          });
        } else {
          fields = _.map(ctrl.totals, (d, k) => {
            return {
              name: ctrl.keyed ? (ctrl.$translate.instant(ctrl.keyed + "." + k) || k) : k,
              id: k,
              key: k,
              orderBy: d
            };
          });
        }

        if (ctrl.orderBy === 'VALUE') {
          fields = _.orderBy(fields, function(s) {
            return s.orderBy;
          }, 'desc');
        }

        ctrl.items = [];

        _.forEach(fields, (d) => {
          const id = _.get(d, 'id');
          d.orderId = DEVICE_ORDER_ID[id] || 1000;
          ctrl.items.push(d);
        });
        ctrl.items = _.orderBy(ctrl.items, 'orderId');

        const customData = [];
        _.forEach(ChartOptionsUtil.getLineItemCustomFields(ctrl.totals), function(f) {
          customData.push({key: f, icon: 'fa-wrench'});
        });
        _.forEach(customData, (d) => {
          ctrl.items.push(d);
        });

        const splitFeesData = [];
        _.forEach(ChartOptionsUtil.getSplitFees(ctrl.totals), function(f) {
          splitFeesData.push({key: f.name, keyBase: 'feeBreakdown', icon: 'fa-money', baseIcon: 'fa-long-arrow-right'});
        });
        let feesIndex = -1;
        for (let i = 0; i < ctrl.items.length; i++) {
          if (ctrl.items[i].key === 'fee') {
            feesIndex = i + 1;
            break;
          }
        }
        _.forEach(splitFeesData, (d) => {
          if (feesIndex > 0) {
            ctrl.items.splice(feesIndex, 0, d);
          } else {
            ctrl.items.push(d);
          }
        });

        ctrl.items = _.filter(ctrl.items, function(item) {
          const hideIfMatchedField = item.hideIfMatched;
          const fieldRef = item.keyBase ? [item.keyBase, item.key] : [item.key || item.id];
          return (_.isFinite(_.get(ctrl.totals, fieldRef)) || _.get(ctrl.totals, fieldRef)) && (!hideIfMatchedField || !valIsEqual(_.get(ctrl.totals, fieldRef), ctrl.totals[hideIfMatchedField]));
        });
      }

      ctrl.$onInit = function() {
        const theTotals = ctrl.changeHook.getTotals();
        ctrl.totals = ctrl.param ? theTotals[ctrl.param] : theTotals;
        rowsOrganiser();
        ctrl.changeHook.chartChangeHandlers.push((totals) => {
          ctrl.totals = ctrl.param ? totals[ctrl.param] : totals;
          rowsOrganiser();
        });
      };
    }
  });

export default MODULE_NAME;