/*
 * Copyright © 2024 Adnuntius AS.
 */

export class EmailHelper {
  static readonly re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  static isEmail(email: string): boolean {
    // copied from https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
    return this.re.test(String(email).toLowerCase());
  }
}
