/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';

const MODULE_NAME = "api-key";

angular.module(MODULE_NAME, [])

  .factory('ApiKey', function(adnResource) {
    return adnResource('apikeys', {}, {}, 'apiKey');
  });

export default MODULE_NAME;