/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import {HttpHelper} from "../../../util/http-helper";

const MODULE_NAME = "report-template";

angular.module(MODULE_NAME, [])

  .factory("ReportTemplate", function(adnResource, $http) {
    let actions = HttpHelper.obtain($http).transformJsonObjActions('components.parameters');
    return adnResource('reporttemplates', {}, actions, 'reportTemplate');
  });

export default MODULE_NAME;