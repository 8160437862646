/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import {ApiConfig} from "./api";

const MODULE_NAME = "auth-provider";

angular.module(MODULE_NAME, [])

  .provider('apiAuth', function() {
    let authResource = '/authenticate',
      authUserResource = '/user/reset-password',
      httpConf = {
        params: {
          context: null
        },
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      };

    this.setResource = function(val) {
      authResource = val;
      return this;
    };

    /**
     * Instantiate the apiAuth factory.
     *
     * We use the $injector here instead of directly injecting the $http service to avoid circular dependency
     * issues with other services that consume this provider as well as the $http service.
     *
     * @param $injector
     * @returns {{authenticate: Function, refresh: Function}}
     */
    this.$get = function($injector) {
      let $http;
      return {
        updatePassword: function(password, authToken) {
          $http = $http || $injector.get('$http');
          const url = ApiConfig.obtain().getVersionedUri(authUserResource) + '?auth_token=' + encodeURIComponent(authToken);
          const passwordHttpConf = angular.copy(httpConf);
          passwordHttpConf.ignoreAuthModule = true;
          return $http.post(url, 'password=' + encodeURIComponent(password), passwordHttpConf).then(function(res) {
            return res.data;
          });
        },
        resetPassword: function(username) {
          $http = $http || $injector.get('$http');
          return $http.post(ApiConfig.obtain().getUri(authResource + '/reset'), 'username=' + encodeURIComponent(username), httpConf).then(function(res) {
            return res.data;
          });
        },
        authenticate: function(username, password, masqUsername, twoFactorAuthCode) {
          $http = $http || $injector.get('$http');
          return $http.post(ApiConfig.obtain().getUri(masqUsername ? "masquerade" : authResource),
            'grant_type=password&scope=ng_api&username=' + encodeURIComponent(username) + '&password=' + encodeURIComponent(password) + (masqUsername ? "&masqueradeUser=" + encodeURIComponent(masqUsername) : "") + (twoFactorAuthCode ? "&twoFactorAuthCode=" + encodeURIComponent(twoFactorAuthCode) : ""), // no need to get fancy, it's just a string
            httpConf).then(function(res) {
            return res.data;
          });
        },
        twoFactorAuth: function(twoFactorAuthCode, authToken) {
          $http = $http || $injector.get('$http');
          return $http.post(ApiConfig.obtain().getUri(authResource + '/2fa'),
            'twoFactorAuthCode=' + encodeURIComponent(twoFactorAuthCode) + '&auth_token=' + encodeURIComponent(authToken),
            httpConf).then(function(res) {
            return res.data;
          });
        },
        refresh: function(refreshToken) {
          $http = $http || $injector.get('$http');
          return $http.post(ApiConfig.obtain().getUri(authResource),
            'grant_type=refresh_token&refresh_token=' + encodeURIComponent(refreshToken),
            httpConf).then(function(res) {
            return res.data;
          });
        }
      };
    };
  });

export default MODULE_NAME;