type EndPointData = {
  id: string;
  scheme: string;
  host: string;
  port: number;
  basePath?: string;
  version?: string;
  type: string;
  env: string;
};
type EndPoint = Record<string, Record<string, EndPointData>>;

export const ALL_END_POINTS: EndPoint = {
  "localhost": {
    "MAUI": {
      "id": "localhost",
      "scheme": "http",
      "host": "localhost",
      "port": 4201,
      "basePath": "/",
      "type": "UI",
      "env": "localhost"
    },
    "ADN_API": {
      "id": "localhost",
      "scheme": "http",
      "host": "localhost",
      "port": 8079,
      "basePath": "/api",
      "version": "v1",
      "type": "API",
      "env": "localhost"
    },
    "ADN_AD_SERVER": {
      "id": "localhost",
      "scheme": "http",
      "host": "localhost",
      "port": 8078,
      "type": "ADSERVER",
      "env": "localhost"
    },
    "ADN_DATA_SERVER": {
      "id": "localhost",
      "scheme": "http",
      "host": "localhost",
      "port": 8078,
      "type": "DATA",
      "env": "localhost"
    }
  },
  "lcl": {
    "ADN_API": {
      "id": "lcl",
      "scheme": "https",
      "host": "api.dev.lcl.test",
      "port": 443,
      "basePath": "/api",
      "version": "v1",
      "type": "API",
      "env": "lcl"
    },
    "ADN_AD_SERVER": {
      "id": "lcl",
      "scheme": "https",
      "host": "adserver.dev.lcl.test",
      "port": 443,
      "type": "ADSERVER",
      "env": "lcl"
    },
    "ADN_DATA_SERVER": {
      "id": "lcl",
      "scheme": "https",
      "host": "dev.data.lcl.test",
      "port": 443,
      "type": "DATA",
      "env": "lcl"
    }
  },
  "dev_adn": {
    "MAUI": {
      "id": "dev_adn",
      "scheme": "https",
      "host": "dev.advertising.adnuntius.com",
      "port": 443,
      "basePath": "/",
      "type": "UI",
      "env": "dev"
    },
    "ADN_API": {
      "id": "dev_adn",
      "host": "api.dev.adnuntius.com",
      "scheme": "https",
      "port": 443,
      "basePath": "/api",
      "version": "v1",
      "type": "API",
      "env": "dev"
    },
    "ADN_AD_SERVER": {
      "id": "dev_adn",
      "host": "adserver.dev.adnuntius.com",
      "scheme": "https",
      "port": 443,
      "type": "ADSERVER",
      "env": "dev"
    },
    "ADN_DATA_SERVER": {
      "id": "dev_adn",
      "host": "dev.data.adnuntius.com",
      "scheme": "https",
      "port": 443,
      "type": "DATA",
      "env": "dev"
    }
  },
  "production_adn": {
    "MAUI": {
      "id": "production_adn",
      "scheme": "https",
      "host": "advertising.adnuntius.com",
      "port": 443,
      "basePath": "/",
      "type": "UI",
      "env": "production"
    },
    "ADN_API": {
      "id": "production_adn",
      "host": "api.adnuntius.com",
      "scheme": "https",
      "port": 443,
      "basePath": "/api",
      "version": "v1",
      "type": "API",
      "env": "production"
    },
    "ADN_AD_SERVER": {
      "id": "production_adn",
      "host": "delivery.adnuntius.com",
      "scheme": "https",
      "port": 443,
      "type": "ADSERVER",
      "env": "production"
    },
    "ADN_DATA_SERVER": {
      "id": "production_adn",
      "host": "data.adnuntius.com",
      "scheme": "https",
      "port": 443,
      "type": "DATA",
      "env": "production"
    }
  },
  "staging_adn": {
    "MAUI": {
      "id": "staging_adn",
      "scheme": "https",
      "host": "staging.advertising.adnuntius.com",
      "port": 443,
      "basePath": "/",
      "type": "UI",
      "env": "staging"
    },
    "ADN_API": {
      "id": "staging_adn",
      "host": "api.staging.adnuntius.com",
      "scheme": "https",
      "port": 443,
      "basePath": "/api",
      "version": "v1",
      "type": "API",
      "env": "staging"
    },
    "ADN_AD_SERVER": {
      "id": "staging_adn",
      "host": "adserver.staging.adnuntius.com",
      "scheme": "https",
      "port": 443,
      "type": "ADSERVER",
      "env": "staging"
    },
    "ADN_DATA_SERVER": {
      "id": "staging_adn",
      "host": "staging.data.adnuntius.com",
      "scheme": "https",
      "port": 443,
      "type": "DATA",
      "env": "staging"
    }
  }
};
