/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';

const MODULE_NAME = "external-demand-source";

angular.module(MODULE_NAME, [])

  .factory("ExternalDemandSource", function(adnResource) {
    return adnResource('externaldemandsources', {}, {}, "externalDemandSource");
  });

export default MODULE_NAME;